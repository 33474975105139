import { CheckCircleTwoTone, CloseCircleTwoTone, ContactsTwoTone, CreditCardTwoTone, DeleteOutlined, EditOutlined, EnvironmentTwoTone, MergeCellsOutlined, UserAddOutlined } from '@ant-design/icons';
import { Affix, Alert, AutoComplete, Button, Checkbox, Col, Collapse, Divider, Empty, Form, Image, Input, Layout, Modal, Radio, Row, Select, Space, Spin, Steps, Table, message } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { isValidIBAN } from 'ibantools';
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams, unstable_usePrompt as usePrompt, useSearchParams } from 'react-router-dom';
import { ConfirmDelete } from '../../components/Customer/ConfirmDelete';
import { EditAdresse } from '../../components/Customer/EditAdresse';
import { EditAssociatedPersons } from '../../components/Customer/EditAssociatedPersons';
import { EditBankverbindung } from '../../components/Customer/EditBankverbindung';
import { EditKontakt } from '../../components/Customer/EditKontakt';
import { MandantIcon } from '../../components/Customer/MandantIcon';
import SaveWarning from '../../components/Customer/SaveWarning';
import { SyncState } from '../../components/Customer/SyncState';
import { KontaktInhalt } from '../../components/Kontakt/KontaktInhalt';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/Topbar';
import BankverbindungService from '../../services/BankverbindungService';
import BindungService from '../../services/BindungService';
import CustomerAddressService from '../../services/CustomerAddressService';
import HistoryService from '../../services/HistoryService';
import KontaktService from '../../services/KontaktService';
import KundenService from '../../services/KundenService';
import LookUpService from '../../services/LookUpService';
import VermittlerService from '../../services/VermittlerService';
import { Bank, Bankverbindung, Bindung, Kontakt, KontaktArt, Kunde, KundenAdresse, LookupLists, Mandant, Vermittler } from '../../services/api-types';
import * as fmt from '../../utils/formatter';
import { compareByKey, errorMessage, handleBeforeunloadEvent, handleErrorResponse, hasElems, hasInclude, hasText, isMissing, isPresent, noElems, noText } from '../../utils/ts_helpers';
import { BindungId, FrontendId } from '../../utils/ui-ids';
import SearchInput from '../../components/Form/SearchInput';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import usePageHistory from '../../hooks/usePageHistory';
import axios from 'axios';

export const noNameWarningMessage = 'Bitte Name eingeben.';

export const sanitizeAdditionalLabel = (e: ChangeEvent<HTMLTextAreaElement>): string => {
    let input = e.target.value;
    let inputSplit = input.split('\n');
    if (inputSplit.length > 2) {
        input = input.slice(0, -1);
    }
    return input;
};

export enum RechtsformID {
    UNBEKANNT = 0,
    PRIVATPERSON = 1,
    EINZELFIRMA = 2,
    FREIBERUFLER = 21,
    EHE = 37,
    WOHNGEMEINSCHAFT = 38,
    LANDWIRTSCHAFTLICHER_BETRIEB = 3,
    FORSTWIRTSCHAFTLICHES_UNTERNEHMEN = 34,
}

export enum Bindungsarten {
    VERTRAGSTEILNEHMER = 112,
    GEMEINSAMER_VERTRAGSPARTNER = 113,
    EHEPARTNER = 11,
    ARBEITGEBER = 8,
    ARBEITERNEHMER = 9,
    FAMILIENMITGLIED = 13,
}

export enum EditMode {
    EDIT_NEW,
    EDIT_EXISTING
}

export enum AddressType {
    RECHNUNG = 1,
    ABWEICHEND = 2,
    ALTADRESSE = 3,
}

export const BANK_FUNCTION = 10;

export const isNaturalPerson = (customer: Kunde): boolean => (customer.rechtsform !== undefined) &&
    [RechtsformID.PRIVATPERSON, RechtsformID.EINZELFIRMA, RechtsformID.FREIBERUFLER, RechtsformID.LANDWIRTSCHAFTLICHER_BETRIEB, RechtsformID.FORSTWIRTSCHAFTLICHES_UNTERNEHMEN].includes(customer.rechtsform);

export const hasRechtsform = (customer: Kunde): boolean => customer.rechtsform !== undefined && customer.rechtsform !== RechtsformID.UNBEKANNT;

const isBank = (customer: Kunde): boolean => customer.bank !== undefined || (customer.funktion !== undefined && customer.funktion.includes(BANK_FUNCTION));

export default function Customer() {

    const location = useLocation();
    const pageHistory = usePageHistory();
    const lastLocation = pageHistory.at(pageHistory.length - 1);

    dayjs.locale('de');

    const TAB_KEY = "activeTab";

    let isFirstLoading = false;

    if (lastLocation !== location.pathname) {
        isFirstLoading = true;
        sessionStorage.removeItem(TAB_KEY);
    }

    window.addEventListener('beforeunload', handleBeforeunloadEvent);

    const { Content } = Layout;

    const [notSaved, setNotSaved] = useState(true);

    const { Option } = Select;

    const { TextArea } = Input;

    const { Panel } = Collapse;

    const [lookup, setLookup] = useState(LookupLists.DEFAULT());

    const [isLookUpLoading, setIsLookUpLoading] = useState(true);

    const [isCustomerLoading, setIsCustomerLoading] = useState(true);

    const [isBindungLoading, setIsBindungLoading] = useState(true);

    const [_lookupBanksLoading, setLookupBanksLoading] = useState(true);

    const [lookupBanks, setLookupBanks] = useState<Kunde[]>([]);

    const [errors, setErrors] = useState<string[]>([]);

    const handleErrors = (error: unknown) => {
        if (axios.isAxiosError(error)) {
            errors.push(`${error.message}`);
        } else {
            errors.push(`${error}`);
        }
    }

    const [messages, setMessages] = useState<string[]>([]);

    const [alertOpen, setAlertOpen] = useState(false);

    const [editMode, setEditMode] = useState(EditMode.EDIT_NEW);

    useEffect(() => {
        LookUpService.getLookUp().then(response => {
            setLookup(response);
        }).catch(error => {
            handleErrorResponse(error)
        }).finally(() => {
            setIsLookUpLoading(false)
        })
    }, []);

    const [currentPerson, setCurrentPerson] = useState(Kunde.DEFAULT());

    const [energieVermittler, setEnergieVermittler] = useState<Kunde>();
    const [energieUntervermittler, setEnergieUntervermittler] = useState<Kunde>();
    const [leasingVermittler, setLeasingVermittler] = useState<Kunde>();
    const [leasingUntervermittler, setLeasingUntervermittler] = useState<Kunde>();

    useEffect(() => {
        let newVermittler: Array<Vermittler> = [];
        if (energieVermittler) {
            if (energieUntervermittler) {
                newVermittler = [...newVermittler, {
                    id: { kunde: currentPerson.id, mandant: Mandant.SMART_DIFFERENT },
                    vermittler: energieVermittler.id,
                    untervermittler: energieUntervermittler.id,
                }];
            } else {
                newVermittler = [...newVermittler, {
                    id: { kunde: currentPerson.id, mandant: Mandant.SMART_DIFFERENT }, vermittler: energieVermittler.id,
                }];
            }
        }
        if (leasingVermittler) {
            if (leasingUntervermittler) {
                newVermittler = [...newVermittler, {
                    id: { kunde: currentPerson.id, mandant: Mandant.SMART_INNOVATION },
                    vermittler: leasingVermittler.id,
                    untervermittler: leasingUntervermittler.id,
                }];
            } else {
                newVermittler = [...newVermittler, {
                    id: { kunde: currentPerson.id, mandant: Mandant.SMART_INNOVATION }, vermittler: leasingVermittler.id,
                }];
            }
        }
        setVermittler(newVermittler);
    }, [energieVermittler, energieUntervermittler, leasingVermittler, leasingUntervermittler,])

    type ConfirmDelete = {
        message: string,
        show: boolean,
        indexRemove: number,
        onDelete: (index: number) => void
    }

    const [confirmDeleteModal, setConfirmDeleteModal] = useState<ConfirmDelete>({ message: '', show: false, indexRemove: -1, onDelete: () => { } })

    const [associatedPersons, setAssociatedPersons] = useState<Record<FrontendId.Type, Kunde>>({});

    const [bindungen, setBindungen] = useState<Record<BindungId.Type, Bindung[]>>({});

    const [deletedBindungen, setDeletedBindungen] = useState<Record<BindungId.Type, Bindung[]>>({});

    const [isAssociatedPersonModalVisible, setIsAssociatedPersonModalVisible] = useState(false);
    const [associatedPersonModalCounter, setAssociatedPersonModalCounter] = useState(0);

    const [deletedBankAccounts, setDeletedBankAccounts] = useState<Array<Bankverbindung>>([]);
    const [deletedAddresses, setDeletedAddresses] = useState<Array<KundenAdresse>>([]);
    const [deletedContacts, setDeletedContacts] = useState<Array<Kontakt>>([]);
    const [deletedAssociatedPersons, setDeletedAssociatedPersons] = useState<Record<FrontendId.Type, Kunde>>({});

    const handleCustomerChange = <K extends keyof Kunde>(prop: K, value: Kunde[K] | null) => {
        setCurrentPerson((currentPerson) => {
            let newPerson = Object.assign({}, currentPerson);
            if (value === null) {
                delete newPerson[prop];
            } else {
                newPerson[prop] = value;
            }
            setNotSaved(true);
            return newPerson;
        });
    };

    const handleCustomerSubmit = async () => {

        try {
            setAlertOpen(true);
            let newBindungen = Object.assign({}, bindungen);
            let newAssociatedPersons = Object.assign({}, associatedPersons);

            const resKunde = currentPerson.id ? await KundenService.updateKunde(currentPerson, currentPerson.id) : await KundenService.createNewKunde(currentPerson);
            let messages: string[] = [];
            let errors: string[] = [];
            messages.push('Die Stammdaten von ' + resKunde.name + ' wurden erfolgreich gespeichert.');

            if (FrontendId.fromKunde(currentPerson).includes('@')) {
                BindungId.keys(newBindungen).filter(bkey => bkey.includes(FrontendId.fromKunde(currentPerson))).forEach(bkey => {
                    let newKey: BindungId.Type;
                    if (BindungId.getSource(bkey) === FrontendId.fromKunde(currentPerson)) {
                        newKey = BindungId.fromKunden(resKunde, newAssociatedPersons[BindungId.getTarget(bkey)]);
                    } else {
                        newKey = BindungId.fromKunden(newAssociatedPersons[BindungId.getSource(bkey)], resKunde);
                    }
                    newBindungen[newKey] = newBindungen[bkey];
                    delete newBindungen[bkey];
                });
            }

            setCurrentPerson(resKunde);
            setMessages(messages);

            try {
                if (hasElems(addressData)) {
                    let addressList = [...addressData.map(address => { address.kunde = resKunde.id; return address; })];
                    const resAddressData = await CustomerAddressService.postCustomerAddressList(addressList);
                    setAddressData(resAddressData.sort(compareByKey(address => address.adressart ?? 4)));
                }
            } catch (error) {
                handleErrors(error);
            }

            await Promise.all(deletedAddresses.map(async address => {
                try {
                    if (isPresent(address.id)) {
                        await CustomerAddressService.deleteCustomerAddress(address.id);
                    }
                } catch (error) {
                    handleErrors(error);
                }
            }));
            setDeletedAddresses([]);

            messages.push('Die Adressdaten von ' + resKunde.name + ' wurde erfolgreich gespeichert.');
            setMessages(messages);

            let newBankAccountData = [...bankAccountData];

            await Promise.all(bankAccountData.map(async (bv, index) => {
                bv.kunde = resKunde.id;
                if (isPresent(bv.id)) {
                    BankverbindungService.updateBankverbindung(bv, bv.id).then(resBV => {
                        console.log([resBV.bank, resBV.bic, resBV.iban].join(', ') + ' wurde für ' + resKunde.name + ' aktualisiert.');
                    }).catch(error => handleErrors(error));
                } else {
                    try {
                        const resBankverbindung = await BankverbindungService.createNewBankverbindung(bv);
                        newBankAccountData.splice(index, 1, resBankverbindung);
                        console.log([resBankverbindung.bank, resBankverbindung.bic, resBankverbindung.iban].join(', ') + ' wurde für ' + resKunde.name + ' angelegt.');
                    } catch (error) {
                        handleErrors(error);
                    }
                }
            }));

            setBankAccountData(newBankAccountData);

            await Promise.all(deletedBankAccounts.map(async ba => {
                try {
                    if (isPresent(ba.id)) {
                        await BankverbindungService.deleteBankverbindung(ba.id);
                    }
                } catch (error) {
                    handleErrors(error);
                }
            }));
            setDeletedBankAccounts([]);

            messages.push('Die Bankverbindungsdaten von ' + resKunde.name + ' wurde erfolgreich gespeichert.');
            setMessages(messages);

            let newContactData = [...contactData];

            await Promise.all(contactData.map(async (contact, index) => {
                contact.kunde = resKunde.id;
                if (isPresent(contact.id)) {
                    KontaktService.updateKontakt(contact, contact.id).then(resContact => {
                        console.log([resContact.kontaktartId, resContact.inhalt, resContact.bezeichnung].join(', ') + ' wurde für ' + resKunde.name + ' aktualisiert.');
                    }).catch(error => {
                        handleErrors(error);
                    });
                } else {
                    try {
                        const resContact = await KontaktService.createNewKontakt(contact);
                        newContactData.splice(index, 1, resContact);
                        console.log([resContact.kontaktartId, resContact.inhalt, resContact.bezeichnung].join(', ') + ' wurde für ' + resKunde.name + ' angelegt.');
                    } catch (error) {
                        handleErrors(error);
                    }
                }
            }));

            setContactData(newContactData);

            await Promise.all(deletedContacts.map(async contact => {
                try {
                    if (isPresent(contact.id)) {
                        await KontaktService.deleteKontakt(contact.id);
                    }
                } catch (error) {
                    handleErrors(error);
                }
            }));
            setDeletedContacts([]);

            messages.push('Die Kontaktdaten von ' + resKunde.name + ' wurde erfolgreich gespeichert.');
            setMessages(messages);

            await Promise.all(vermittler.map(async v => {
                if (isPresent(v.id)) {
                    v.id.kunde = resKunde.id;
                    try {
                        await VermittlerService.create(v);
                        console.log(v.id?.mandant === Mandant.SMART_DIFFERENT ? 'Energievermittler wurde angelegt.' : 'Leasingvermittler wurde angelegt.');
                    } catch (error) {
                        handleErrors(error);
                    }
                }
            }));

            if (noElems(vermittler)) {
                if (resKunde.id) {
                    try {
                        await VermittlerService.delete({ kunde: resKunde.id, mandant: 0 });
                        await VermittlerService.delete({ kunde: resKunde.id, mandant: 1 });
                    } catch (error) {
                        handleErrors(error);
                    }
                }
            }

            await Promise.all(FrontendId.keys(associatedPersons).map(async key => {
                try {
                    const resAssociatedPerson = associatedPersons[key].id ? associatedPersons[key] : await KundenService.createNewKunde(associatedPersons[key]);

                    if (key.includes('@')) {
                        let newAPKey = FrontendId.fromKunde(resAssociatedPerson);
                        delete newAssociatedPersons[key];
                        newAssociatedPersons[newAPKey] = resAssociatedPerson;

                        BindungId.keys(newBindungen).forEach(bkey => {
                            if (BindungId.getSource(bkey) === key) {
                                const newBindungId = BindungId.fromKunden(resAssociatedPerson, newAssociatedPersons[BindungId.getTarget(bkey)] ? newAssociatedPersons[BindungId.getTarget(bkey)] : resKunde);
                                newBindungen[newBindungId] = newBindungen[bkey];
                                delete newBindungen[bkey];
                            }
                            if (BindungId.getTarget(bkey) === key) {
                                const newBindungId = BindungId.fromKunden(newAssociatedPersons[BindungId.getSource(bkey)] ? newAssociatedPersons[BindungId.getSource(bkey)] : resKunde, resAssociatedPerson);
                                newBindungen[newBindungId] = newBindungen[bkey];
                                delete newBindungen[bkey];
                            }
                        });

                    }

                } catch (error) {
                    handleErrors(error);
                }
            }));

            setAssociatedPersons(newAssociatedPersons);

            await Promise.all(BindungId.keys(deletedBindungen).map(async bkey => {
                const kunde =
                    BindungId.getSource(bkey) === FrontendId.fromKunde(resKunde)
                        ? resKunde
                        : deletedAssociatedPersons[BindungId.getSource(bkey)] ?? newAssociatedPersons[BindungId.getSource(bkey)];
                const zkunde =
                    BindungId.getTarget(bkey) === FrontendId.fromKunde(resKunde)
                        ? resKunde
                        : deletedAssociatedPersons[BindungId.getTarget(bkey)] ?? newAssociatedPersons[BindungId.getTarget(bkey)];

                try {
                    await Promise.all(deletedBindungen[bkey].map(async value => {
                        try {
                            await BindungService.deleteBindung({
                                id: {
                                    kunde: kunde.id,
                                    zielKunde: zkunde.id,
                                    bindungsart: value.id.bindungsart,
                                },
                                details: value.details,
                            });
                        } catch (error) {
                            handleErrors(error);
                        }
                    }));
                } catch (error) {
                    handleErrors(error);
                }
            }));
            setDeletedBindungen({});

            await Promise.all(BindungId.keys(newBindungen).map(async bkey => {
                const kunde =
                    BindungId.getSource(bkey) === FrontendId.fromKunde(resKunde)
                        ? resKunde
                        : newAssociatedPersons[BindungId.getSource(bkey)] ?? deletedAssociatedPersons[BindungId.getSource(bkey)];
                const zkunde =
                    BindungId.getTarget(bkey) === FrontendId.fromKunde(resKunde)
                        ? resKunde
                        : newAssociatedPersons[BindungId.getTarget(bkey)] ?? deletedAssociatedPersons[BindungId.getTarget(bkey)];

                await Promise.all(newBindungen[bkey].map(async value => {
                    try {
                        const resBindung = await BindungService.createNewBindung({
                            id: {
                                kunde: kunde.id,
                                zielKunde: zkunde.id,
                                bindungsart: value.id.bindungsart,
                            },
                            details: value.details,
                        });

                        console.log('Bindung ' + lookup.bindungsartList.find(b => b.id === resBindung.id.bindungsart)?.bindungsart +
                            ' zwischen ' +
                            fmt.kunde(kunde) +
                            ' und ' +
                            fmt.kunde(zkunde) +
                            + ' wurde erfolgreich angelegt.');

                    } catch (error) {

                    }

                }));
            }));

            setBindungen(newBindungen);

            messages.push('Die Bindungsdaten von ' + resKunde.name + ' wurde erfolgreich gespeichert.');

            setMessages(messages);
            setErrors(errors);
        } catch (error) {
            setErrors([...errors, `${error}`]);
        } finally {
            setNotSaved(false);
        }
    }

    const [editAddressMode, setEditAddressMode] = useState(EditMode.EDIT_NEW);

    const [address, setAddress] = useState(KundenAdresse.DEFAULT());
    const [addressIndex, setAddressIndex] = useState(-1);

    const [editAssociatedPersonMode, setEditAssociatedPersonMode] = useState(EditMode.EDIT_NEW);

    const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);

    const [associatedPerson, setAssociatedPerson] = useState(Kunde.DEFAULT());

    const [vermittler, setVermittler] = useState<Array<Vermittler>>([]);

    const showCurrentAssociatedPersonModal = (currentAssociatedPersonIdx: number | undefined, person: Kunde) => {
        setEditAssociatedPersonMode(currentAssociatedPersonIdx === undefined ? EditMode.EDIT_NEW : EditMode.EDIT_EXISTING);

        if (currentAssociatedPersonIdx === undefined) {
            setAssociatedPerson({
                name: '',
                anrede: 'Sehr geehrte Damen und Herren',
                smartDifferent: false,
                smartInnovation: false,
            })
        } else {
            setAssociatedPerson(person)
        }
        setAssociatedPersonModalCounter(associatedPersonModalCounter + 1);
        setIsAssociatedPersonModalVisible(true);
    }

    const handleAddressSubmit = (newAddress: KundenAdresse) => {
        let newAddressData = [...addressData];
        let address = Object.assign({}, newAddress);
        if (editAddressMode === EditMode.EDIT_NEW) {
            newAddressData = [...newAddressData, address];
        } else {
            if (addressIndex < 0) {
                console.error("Edited address not found: ", address, addressData);
                message.error("Geänderte Adresse nicht gefunden!");
                return;
            }
            newAddressData.splice(addressIndex, 1, newAddress);
        }

        setAddressData(newAddressData);
        setNotSaved(true);
        setIsAddressModalVisible(false);
    };

    const handleAddressDelete = (address: KundenAdresse) => {
        let newAddressData = [...addressData.filter(a => a !== address)];
        setConfirmDeleteModal({ message: '', show: false, indexRemove: -1, onDelete: () => { } });
        setAddressData(newAddressData);
        setDeletedAddresses([...deletedAddresses, address])
    };

    const handleAssociatedPersonDelete = (currentAssociatedPersonIdx: number) => {
        let arrAssociatedPersons = FrontendId.keys(associatedPersons).map(idx =>
            associatedPersons[idx]
        );

        let associatedPerson = arrAssociatedPersons[currentAssociatedPersonIdx];
        let newBindungen = Object.assign({}, bindungen)
        let newAssociatedPersons = Object.assign({}, associatedPersons)
        const associatedPersonId = associatedPerson.id;
        if (associatedPersonId !== undefined) {
            let deletedBindings: Record<BindungId.Type, Bindung[]> = {};
            deletedBindings[BindungId.fromKunden(currentPerson, associatedPerson)] = newBindungen[BindungId.fromKunden(currentPerson, associatedPerson)];
            deletedBindings[BindungId.fromKunden(associatedPerson, currentPerson)] = newBindungen[BindungId.fromKunden(associatedPerson, currentPerson)];
            setDeletedBindungen({ ...deletedBindungen, ...deletedBindings });
            let deletedAssociatedPerson: Record<FrontendId.Type, Kunde> = {};
            deletedAssociatedPerson[FrontendId.fromKunde(associatedPerson)] = associatedPerson;
            setDeletedAssociatedPersons({ ...deletedAssociatedPersons, ...deletedAssociatedPerson });
        }
        delete newAssociatedPersons[FrontendId.fromKunde(associatedPerson)]
        delete newBindungen[BindungId.fromKunden(currentPerson, associatedPerson)]
        delete newBindungen[BindungId.fromKunden(associatedPerson, currentPerson)]

        setConfirmDeleteModal({ message: '', show: false, indexRemove: -1, onDelete: () => { } })
        setBindungen(newBindungen)
        setAssociatedPersons(newAssociatedPersons)
    }

    const handleAddressCancel = () => {
        setIsAddressModalVisible(false);
    };


    const [editBankverbindungMode, setEditBankverbindungMode] = useState(EditMode.EDIT_NEW);

    const [bankverbindungIndex, setBankverbindungIndex] = useState(-1);

    const [isBankverbindungModalVisible, setIsBankverbindungModalVisible] = useState(false);

    const [bankverbindung, setBankverbindung] = useState<Bankverbindung>(Bankverbindung.DEFAULT());

    const showBankverbindungModal = (bankverbindung: Bankverbindung, isEdit: EditMode) => {
        setEditBankverbindungMode(isEdit);
        setBankverbindung(bankverbindung);
        setIsBankverbindungModalVisible(true);
    };

    const handleBankverbindungSubmit = (newBankverbindung: Bankverbindung) => {
        if (hasText(newBankverbindung.iban)) {
            if (!isValidIBAN(newBankverbindung.iban.replace(/\s/g, ''))) {
                message.warning('IBAN ist ungültig. Bitte eine gültige IBAN eingeben.');
            } else if (newBankverbindung.iban.replace(/\s/g, '').substring(4, 12) !== newBankverbindung.blz?.replace(/\s/g, '')) {
                message.warning('IBAN und Bankleitzahl sind nicht konsistent.')
            } else {
                KundenService.findAllById(
                    [
                        ...(bankAccountData ?? []).map(b => b.bank),
                        newBankverbindung.bank
                    ].filter(isPresent).filter((value, index, array) => array.indexOf(value) === index))
                    .then(banks => setLookupBanks(banks))
                    .catch(error => errorMessage(error))
                    .finally(() => setLookupBanksLoading(false));

                let newBankAccountData = [...bankAccountData];
                if (editBankverbindungMode === EditMode.EDIT_NEW) {
                    newBankAccountData = [...newBankAccountData, newBankverbindung];
                } else {
                    if (bankverbindungIndex < 0) {
                        console.error("Edited contact not found: ", contact, contactData);
                        message.error("Geänderter Kontakt nicht gefunden!");
                        return;
                    } else {
                        newBankAccountData.splice(bankverbindungIndex, 1, newBankverbindung);
                    }
                }

                setBankAccountData(newBankAccountData);
                setNotSaved(true);
                setIsBankverbindungModalVisible(false);
            }
        } else {
            message.warning('IBAN ist leer. Bitte eine gültige IBAN eingeben.');
        }
    };

    const handleBankverbindungDelete = (bankverbindung: Bankverbindung) => {
        let newBankAccountData = [...bankAccountData.filter(b => b !== bankverbindung)];
        setConfirmDeleteModal({ message: '', show: false, indexRemove: -1, onDelete: () => { } })
        setBankAccountData(newBankAccountData);
        setDeletedBankAccounts([...deletedBankAccounts, bankverbindung]);
    };

    const handleBankverbindungCancel = () => {
        setIsBankverbindungModalVisible(false);
    };

    const [editContactMode, setEditContactMode] = useState(EditMode.EDIT_NEW);

    const [contact, setContact] = useState(Kontakt.DEFAULT());

    const [contactModalVisible, setContactModalVisible] = useState(false);

    const [contactIndex, setContactIndex] = useState(-1);

    const getRegionCode = (address?: KundenAdresse): string => {
        let regionCode = 'DE';
        if (isPresent(address)) {
            switch (address.land) {
                case 'Spanien': {
                    regionCode = 'ES';
                    break;
                }
                case 'Luxemburg': {
                    regionCode = 'LU';
                    break;
                }
                case 'Frankreich': {
                    regionCode = 'FR';
                    break;
                }
                case 'Polen': {
                    regionCode = 'PO';
                    break;
                }
                default: break;
            }
        }
        return regionCode;
    }

    const handleContactSubmit = (newContact: Kontakt) => {

        if (newContact.kontaktartId === undefined || !(newContact.kontaktartId in KontaktArt)) {
            message.error('Bitte eine Kontaktart auswählen.');
            return;
        }

        if (isMissing(newContact.inhalt)) {
            message.error('Bitte den Inhalt des Kontakts vervollständigen.');
            return;
        }

        if (Kontakt.isEMail(newContact.kontaktartId)) {
            if (!isValidEMail(newContact.inhalt)) {
                message.error('Die E-Mail-Adresse ist ungültig. Bitte eine gültige E-Mail-Adresse eingeben.');
                return;
            }
        }

        if (Kontakt.isUrl(newContact.kontaktartId)) {
            if (!newContact.inhalt?.match(/^https?:/)) {
                message.error('Die Adresse der Homepage muss mit "http:" oder "https:" beginnen.')
                return;
            }
            if (newContact.inhalt?.match(/\s/)) {
                message.error('Die Adresse der Homepage darf keine Leerzeichen enthalten.')
                return;
            }
        }

        let newContactData = [...contactData];
        if (editContactMode === EditMode.EDIT_NEW) {
            newContactData = [...newContactData, newContact];
        } else {
            if (contactIndex < 0) {
                console.error("Edited contact not found: ", contact, contactData);
                message.error("Geänderter Kontakt nicht gefunden!");
                return;

            } else {
                newContactData.splice(contactIndex, 1, newContact);
            }
        }

        setContactData(newContactData);
        setContact(Kontakt.DEFAULT());
        setNotSaved(true);
        setContactModalVisible(false);
    };

    const handleContactsDelete = (contact: Kontakt) => {
        setConfirmDeleteModal({ message: '', show: false, indexRemove: -1, onDelete: () => { } })
        setContactData([...contactData.filter(c => c !== contact)]);
        setDeletedContacts([...deletedContacts, contact]);
    };

    const [searchParams, _setSearchParams] = useSearchParams();

    useEffect(() => {
        const branche = searchParams.get("branche")?.split(',').map(b => parseInt(b));
        const funktion = searchParams.get("funktion")?.split(',').map(f => parseInt(f));
        setCurrentPerson(prev => {
            return {
                ...prev,
                branche: branche,
                funktion: funktion,
            }
        });
    }, [searchParams]);

    // read parameter from URL ("customer/:id" in App.tsx)
    const { id } = useParams();

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (id === undefined) {
            setIsCustomerLoading(false);
            setIsBindungLoading(false);
            setEditMode(EditMode.EDIT_NEW);
        } else {
            KundenService.getKundeById(parseInt(id)).then(response => {
                const savedTabKey = sessionStorage.getItem(TAB_KEY);
                if (!isFirstLoading) {
                    if (savedTabKey) {
                        setCurrentStep(parseInt(savedTabKey));
                    }
                }
                setEditMode(EditMode.EDIT_EXISTING);
                setCurrentPerson({ ...response, adressen: [], kontakte: [], bindungen: [], bankverbindungen: [] });
                if (response.adressen) {
                    setAddressData(response.adressen);
                }
                if (response.bindungen) {
                    let ids: number[] = [];
                    response.bindungen.filter(bindung => isPresent(bindung.id.zielKunde)).forEach(b => { if (isPresent(b.id.zielKunde) && ids.indexOf(b.id.zielKunde) === -1) ids.push(b.id.zielKunde) });
                    KundenService.findAllById(ids).then(kunden => {
                        let newAssociatedPersons: Record<FrontendId.Type, Kunde> = {};
                        let newBindungen: Record<BindungId.Type, Bindung[]> = {};
                        kunden.forEach(k => {
                            newAssociatedPersons[FrontendId.fromKunde(k)] = k;
                            if (response.bindungen !== undefined) {
                                newBindungen[BindungId.fromKunden(response, k)] = response.bindungen.filter(b => b.id.zielKunde === k.id);
                            }
                            if (k.bindungen !== undefined) {
                                newBindungen[BindungId.fromKunden(k, response)] = k.bindungen.filter(b => b.id.zielKunde === response.id);
                                kunden.filter(c => c !== k).forEach(c => {
                                    if (k.bindungen !== undefined) {
                                        newBindungen[BindungId.fromKunden(k, c)] = k.bindungen.filter(b => b.id.zielKunde === c.id);
                                    }
                                    if (c.bindungen !== undefined) {
                                        newBindungen[BindungId.fromKunden(c, k)] = c.bindungen.filter(b => b.id.zielKunde === k.id);
                                    }
                                });
                            }
                        });
                        setAssociatedPersons(newAssociatedPersons);
                        setBindungen(newBindungen);
                    }).catch(error => errorMessage(error))
                        .finally(() => setIsBindungLoading(false));
                }
                if (response.bankverbindungen) {
                    setBankAccountData(response.bankverbindungen);
                }
                if (response.kontakte) {
                    setContactData(response.kontakte);
                }

                if (response.vermittler) {
                    // quick hack, but in next version typescript this error will be fixed
                    const vermittlerIds: number[] = response.vermittler.map(v => [v.untervermittler!, v.vermittler!]).flatMap(v => v).filter(v => v !== undefined);
                    KundenService.findAllById(vermittlerIds)
                        .then(vermittlerList => {
                            const smartDifferentVermittler = response.vermittler?.find(v => v.id?.mandant === Mandant.SMART_DIFFERENT);
                            const smartInnovationVermittler = response.vermittler?.find(v => v.id?.mandant === Mandant.SMART_INNOVATION);
                            if (smartDifferentVermittler) {
                                if (smartDifferentVermittler.vermittler) {
                                    setEnergieVermittler(vermittlerList.find(v => v.id === smartDifferentVermittler.vermittler));
                                }
                                if (smartDifferentVermittler.untervermittler) {
                                    setEnergieUntervermittler(vermittlerList.find(v => v.id === smartDifferentVermittler.untervermittler));
                                }
                            }
                            if (smartInnovationVermittler) {
                                if (smartInnovationVermittler.vermittler) {
                                    setLeasingVermittler(vermittlerList.find(v => v.id === smartInnovationVermittler.vermittler));
                                }
                                if (smartInnovationVermittler.untervermittler) {
                                    setLeasingUntervermittler(vermittlerList.find(v => v.id === smartInnovationVermittler.untervermittler));
                                }
                            }
                        }).catch(error => errorMessage(error));
                }

                KundenService.findAllById(response.bankverbindungen?.map(b => b.bank!) || [])
                    .then(banks => setLookupBanks(banks))
                    .catch(error => errorMessage(error))
                    .finally(() => setLookupBanksLoading(false));

            }).catch(error => errorMessage(error))
                .finally(() => setIsCustomerLoading(false));
        }
    }, [id]);

    const onAdditionalLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let input = sanitizeAdditionalLabel(e);
        handleCustomerChange('zusatzbezeichnung', input);
    };

    const [showSpouseButton, setShowSpouseButton] = useState<boolean>(true);

    /* save warning variables and functions  */

    const [saveCustomerWarningVisible, setSaveCustomerWarningVisible] = useState(false);

    const [saveCustomerWarningMessage, setSaveCustomerWarningMessage] = useState('');

    const handleSaveCustomerCancel = () => {
        setSaveCustomerWarningMessage('');
        setSaveCustomerWarningVisible(false);
    };

    const handleSaveCustomerConfirm = () => {
        handleCustomerSubmit();
    };

    const handleCustomerSave = () => {

        if (addressError !== '') {
            message.error(addressError);
            return;
        }

        if (currentPerson.id) {
            HistoryService.check('kunden', currentPerson.id).then(response => {
                if (response.result) {
                    const message = 'Der Kunde ' + fmt.kunde(currentPerson) + ' ist vor weniger als 10 Minuten von ' + response.usernameList.join(', ') + ' geändert worden.'
                    setSaveCustomerWarningMessage(message);
                    setSaveCustomerWarningVisible(true);
                } else {
                    handleCustomerSubmit();
                }
            }).catch(error => {
                setErrors([...errors, `${error}`]);
            });
        } else {
            handleCustomerSubmit();
        }
    };

    /* states and functions for customer search */

    const [customerSearchDropdownVisible, setCustomerSearchDropdownVisible] = useState(false);
    const [customerSearchResult, setCustomerSearchResult] = useState<Kunde[]>([]);
    const [isCustomerSearching, setIsCustomerSearching] = useState(false);
    const [customerSearchAbortController, setCustomerSearchAbortController] = useState<AbortController>();

    const searchCustomerByName = async (key: string) => {
        try {
            if (customerSearchAbortController) {
                customerSearchAbortController.abort();
            }
            const newAbortController = new AbortController();
            setCustomerSearchAbortController(newAbortController);
            const rechtsform = lookup.rechtsformList.filter(r => r.rechtsform.toLowerCase().includes(key.toLowerCase())).map(r => r.id);
            const result = await KundenService.getAllKundenByKeyAndRechtsform(key, newAbortController.signal, rechtsform);
            setCustomerSearchResult(result);
            if (result.length === 0) {
                setCustomerSearchDropdownVisible(false);
            }
        } catch (error) {
            handleErrorResponse(error);
        } finally {
            setIsCustomerSearching(false);
        }
    }

    const handleSelectedCustomerSearch = (id: number) => {
        try {
            const newCurrentPerson = customerSearchResult.find(customer => customer.id === id);
            if (newCurrentPerson) {
                setEditMode(EditMode.EDIT_EXISTING);
                setCurrentPerson(newCurrentPerson);
                setAddressData(newCurrentPerson.adressen ?? []);
                if (newCurrentPerson.bindungen) {
                    let ids: number[] = [];
                    newCurrentPerson.bindungen.filter(bindung => isPresent(bindung.id.zielKunde)).forEach(b => { if (isPresent(b.id.zielKunde) && ids.indexOf(b.id.zielKunde) === -1) ids.push(b.id.zielKunde) });
                    KundenService.findAllById(ids).then(kunden => {
                        let newAssociatedPersons: Record<FrontendId.Type, Kunde> = {};
                        let newBindungen: Record<BindungId.Type, Bindung[]> = {};
                        kunden.forEach(k => {
                            newAssociatedPersons[FrontendId.fromKunde(k)] = k;
                            if (newCurrentPerson.bindungen !== undefined) {
                                newBindungen[BindungId.fromKunden(newCurrentPerson, k)] = newCurrentPerson.bindungen.filter(b => b.id.zielKunde === k.id);
                            }
                            if (k.bindungen !== undefined) {
                                newBindungen[BindungId.fromKunden(k, newCurrentPerson)] = k.bindungen.filter(b => b.id.zielKunde === newCurrentPerson.id);
                                kunden.filter(c => c !== k).forEach(c => {
                                    if (k.bindungen !== undefined) {
                                        newBindungen[BindungId.fromKunden(k, c)] = k.bindungen.filter(b => b.id.zielKunde === c.id);
                                    }
                                    if (c.bindungen !== undefined) {
                                        newBindungen[BindungId.fromKunden(c, k)] = c.bindungen.filter(b => b.id.zielKunde === k.id);
                                    }
                                });
                            }
                        });
                        setAssociatedPersons(newAssociatedPersons);
                        setBindungen(newBindungen);
                    }).catch(error => errorMessage(error))
                        .finally(() => setIsBindungLoading(false));
                } else {
                    setAssociatedPersons({});
                    setBindungen({});
                }

                setBankAccountData(newCurrentPerson.bankverbindungen ?? []);

                setContactData(newCurrentPerson.kontakte ?? []);

                if (hasElems(newCurrentPerson.bankverbindungen)) {
                    KundenService.findAllById(newCurrentPerson.bankverbindungen.map(b => b.bank).filter(isPresent))
                        .then(banks => setLookupBanks(banks))
                        .catch(error => errorMessage(error))
                        .finally(() => setLookupBanksLoading(false));
                }
            }
            setCustomerSearchDropdownVisible(false);
        } catch (error) {
            handleErrorResponse(`Oooops, Es ist irgendwie schief gelaufen. Error: ${error}`);
        }
    }

    const customerSearchOptions = () =>
        [{
            title: '',
            label: (isCustomerSearching ? <Row justify='center'><Spin size='large'></Spin></Row> :
                <Row>
                    <Col span={12}>Name, Vorname</Col>
                    <Col span={4}>Rechtsform</Col>
                    <Col span={6}>Adresse</Col>
                    <Col span={1}>Mandant</Col>
                </Row>
            ),
            options: isCustomerSearching ? [] : customerSearchResult
                .map(customer => ({
                    value: customer.id,
                    label: <Row>
                        <Col span={12}>{fmt.kunde(customer)}</Col>
                        <Col span={4}>{lookup.rechtsformList.find((r) => r.id === customer.rechtsform)?.rechtsform}</Col>
                        <Col span={6}>{fmt.adresse(customer.adressen?.[0])}</Col>
                        <Col span={1}>{fmt.mandant(customer)}</Col>
                    </Row>
                }))
        }];

    /* states and functions for address search */

    const [addressStreetField, setAddressStreetField] = useState('');
    const [addressNumberField, setAddressNumberField] = useState('');
    const [addressZipcodeField, setAddressZipcodeField] = useState('');
    const [addressLocationField, setAddressLocationField] = useState('');
    const [addressDistrictField, setAddressDistrictField] = useState('');
    const [addressCountryField, setAddressCountryField] = useState('');
    const [addressTypeField, setAddressTypeField] = useState<number>();

    // primary address list, does not change when filtering is active
    const [addressData, setAddressData] = useState<Array<KundenAdresse>>([]);
    const [addressWarning, setAddressWarning] = useState('');
    const [addressWarningVisible, setAddressWarningVisible] = useState(false);
    const [addressError, setAddressError] = useState('');

    useEffect(() => {
        if (addressData.filter(a => a.adressart === AddressType.RECHNUNG).length > 1) {
            message.error('Es darf maximal nur eine Rechnungsadresse vorhanden sein.');
            setAddressWarningVisible(true);
            setAddressWarning('Es darf maximal nur eine Rechnungsadresse vorhanden sein.');
            setAddressError('Es darf maximal nur eine Rechnungsadresse vorhanden sein.');
        }

        if (addressData.length > 0 && addressData.find(a => a.adressart === AddressType.RECHNUNG) === undefined) {
            message.error('Mindestens eine Rechnungsadresse muss angelegt werden.');
            setAddressWarningVisible(true);
            setAddressWarning('Mindestens eine Rechnungsadresse muss angelegt werden.');
        }

        if (addressData.filter(a => a.adressart === AddressType.RECHNUNG).length === 1) {
            setAddressWarningVisible(false);
            setAddressWarning('');
            setAddressError('');
        }

    }, [addressData]);

    const handleAddressFilter = useCallback(() => {
        let newAddresses = addressData;
        if (hasText(addressStreetField)) {
            newAddresses = newAddresses.filter(a => hasInclude(addressStreetField, a.strasse));
        }
        if (hasText(addressNumberField)) {
            newAddresses = newAddresses.filter(a => hasInclude(addressNumberField, a.hausnr));
        }
        if (hasText(addressZipcodeField)) {
            newAddresses = newAddresses.filter(a => hasInclude(addressZipcodeField, a.plz));
        }
        if (hasText(addressLocationField)) {
            newAddresses = newAddresses.filter(a => hasInclude(addressLocationField, a.ort));
        }
        if (hasText(addressDistrictField)) {
            newAddresses = newAddresses.filter(a => hasInclude(addressDistrictField, a.ortsteil));
        }
        if (hasText(addressCountryField)) {
            newAddresses = newAddresses.filter(a => hasInclude(addressCountryField, a.land));
        }
        if (addressTypeField !== undefined) {
            newAddresses = newAddresses.filter(a => a.adressart === addressTypeField);
        }
        return newAddresses;
    }, [addressStreetField, addressNumberField, addressZipcodeField, addressLocationField, addressDistrictField, addressCountryField, addressTypeField, addressData]);

    const resetAddressFilter = () => {
        setAddressStreetField('');
        setAddressNumberField('');
        setAddressZipcodeField('');
        setAddressLocationField('');
        setAddressDistrictField('');
        setAddressCountryField('');
        setAddressTypeField(undefined);
    };

    useEffect(() => {
        handleAddressFilter();
    }, [handleAddressFilter]);

    /* states and functions for bank account search */

    const [bankAccountBankField, setBankAccountBankField] = useState('');
    const [bankAccountIbanField, setBankAccountIbanField] = useState('');
    const [bankAccountBicField, setBankAccountBicField] = useState('');
    const [bankAccountAccountNumberField, setBankAccountAccountNumberField] = useState('');
    const [bankAccountBankCodeField, setBankAccountBankCodeField] = useState('');
    const [bankAccountCommentField, setBankAccountCommentField] = useState('');
    const [bankAccountErstellungszeitpunktField, setBankAccountErstellungsZeitpunktField] = useState('');
    const [bankAccountAnpassungszeitpunktField, setBankAccountAnpassungsZeitpunktField] = useState('');

    // primary bank account list, does not change when filtering is active
    const [bankAccountData, setBankAccountData] = useState<Array<Bankverbindung>>([]);

    const handleBankAccountFilter = useCallback(() => {
        let newBankverbindungen = bankAccountData;
        if (hasText(bankAccountBankField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountBankField, lookupBanks.find(lb => lb.id === b.bank)?.name));
        }
        if (hasText(bankAccountIbanField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountIbanField, b.iban));
        }
        if (hasText(bankAccountBicField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountBicField, b.bic));
        }
        if (hasText(bankAccountAccountNumberField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountAccountNumberField, b.konto));
        }
        if (hasText(bankAccountBankCodeField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountBankCodeField, b.blz));
        }
        if (hasText(bankAccountCommentField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountCommentField, b.kommentar));
        }
        if (hasText(bankAccountErstellungszeitpunktField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountErstellungszeitpunktField, fmt.zeitpunkt(b.erstellungszeitpunkt)));
        }
        if (hasText(bankAccountAnpassungszeitpunktField)) {
            newBankverbindungen = newBankverbindungen.filter(b => hasInclude(bankAccountAnpassungszeitpunktField, fmt.zeitpunkt(b.anpassungszeitpunkt)));
        }
        return newBankverbindungen;
    }, [bankAccountBankField, bankAccountIbanField, bankAccountBicField, bankAccountAccountNumberField, bankAccountBankCodeField, bankAccountCommentField,
        bankAccountErstellungszeitpunktField, bankAccountAnpassungszeitpunktField, bankAccountData]);

    const resetBankAccountFilter = () => {
        setBankAccountBankField('');
        setBankAccountIbanField('');
        setBankAccountBicField('');
        setBankAccountAccountNumberField('');
        setBankAccountBankCodeField('');
        setBankAccountCommentField('');
    };

    useEffect(() => {
        handleBankAccountFilter();
    }, [handleBankAccountFilter]);

    /* states and functions for contact search */

    const [contactTypeField, setContactTypeField] = useState<number>();
    const [contactContentField, setContactContentField] = useState('');
    const [contactDescriptionField, setContactDescriptionField] = useState('');

    // primary contact list, does not change when filtering is active
    const [contactData, setContactData] = useState<Array<Kontakt>>([]);

    const handleContactFilter = useCallback(() => {
        let newContacts = [...contactData];
        if (contactTypeField !== undefined) {
            newContacts = newContacts.filter(c => c.kontaktartId === contactTypeField);
        }
        if (hasText(contactContentField)) {
            newContacts = newContacts.filter(c => hasInclude(contactContentField, c.inhalt));
        }
        if (hasText(contactDescriptionField)) {
            newContacts = newContacts.filter(c => hasInclude(contactDescriptionField, c.bezeichnung));
        }
        return newContacts;
    }, [contactTypeField, contactContentField, contactDescriptionField, contactData]);

    const resetContactFilter = () => {
        setContactTypeField(undefined);
        setContactContentField('');
        setContactDescriptionField('');
    };

    useEffect(() => {
        handleContactFilter();
    }, [handleContactFilter]);

    /* states and functions for binding search  */

    const [bindingForenameField, setBindingForenameField] = useState('');
    const [bindingSurnameField, setBindingSurnameField] = useState('');
    const [bindingAdditionalField, setBindingAdditionalField] = useState('');
    const [bindingTypeField, setBindingTypeField] = useState<number[]>([]);
    const [bindingContactsField, setBindingContactsField] = useState('');
    const [bindingDetailsField, setBindingDetailsField] = useState('');

    const handleBindingFilter = useCallback(() => {
        let newBindings: Record<FrontendId.Type, Kunde> = { ...associatedPersons };
        if (hasText(bindingForenameField)) {
            FrontendId.keys(newBindings)
                .filter(key => !hasInclude(bindingForenameField, newBindings[key].vorname))
                .forEach(key => delete newBindings[key]);
        }
        if (hasText(bindingSurnameField)) {
            FrontendId.keys(newBindings)
                .filter(key => !hasInclude(bindingSurnameField, newBindings[key].name))
                .forEach(key => delete newBindings[key]);
        }
        if (hasText(bindingAdditionalField)) {
            FrontendId.keys(newBindings)
                .filter(key => !hasInclude(bindingAdditionalField, newBindings[key].zusatzbezeichnung))
                .forEach(key => delete newBindings[key]);
        }
        if (hasText(bindingDetailsField)) {
            FrontendId.keys(newBindings)
                .filter(key => !hasInclude(bindingDetailsField, newBindings[key].bindungen?.map(b => b.details).join(',')))
                .forEach(key => delete newBindings[key]);
        }
        if (bindingTypeField.length > 0) {
            FrontendId.keys(newBindings)
                .filter(key => !bindungen[BindungId.fromKunden(newBindings[key], currentPerson)].some(b => b.id.bindungsart ? bindingTypeField.includes(b.id.bindungsart) : false))
                .forEach(key => delete newBindings[key]);
        }
        const formatKontakt = (kontakt: Kontakt) => {
            return [lookup.kontaktartList.find(ka => ka.id === kontakt.kontaktartId)?.kontaktart, kontakt.inhalt, kontakt.bezeichnung].filter(hasText).join(',')
        }
        if (hasText(bindingContactsField)) {
            FrontendId.keys(newBindings)
                .filter(key => !hasInclude(bindingContactsField, newBindings[key].kontakte?.map(k => formatKontakt(k)).join(',')))
                .forEach(key => delete newBindings[key]);
        }
        return newBindings;
    }, [bindingForenameField, bindingSurnameField, bindingAdditionalField, bindingTypeField, bindingContactsField, bindingDetailsField, associatedPersons]);

    const resetBindingFilter = () => {
        setBindingForenameField('');
        setBindingSurnameField('');
        setBindingTypeField([]);
        setBindingContactsField('');
    };

    useEffect(() => {
        handleBindingFilter();
    }, [handleBindingFilter]);

    /* states and functions for merging suggestion */

    interface Suggest {
        energie: Kunde;
        leasing: Kunde;
        link: string;
    }

    const [suggests, setSuggests] = useState<Array<Suggest>>([]);

    useEffect(() => {
        setSuggests(_prev => {
            const list = FrontendId.keys(handleBindingFilter())
                .map(idx => associatedPersons[idx])
                .filter(person => !person.deleteDate);

            let idList: number[] = [];

            let newSuggests = list.map((p: Kunde): Suggest | undefined => {

                let candidat: Kunde | undefined;

                if (p.id !== undefined && !idList.includes(p.id)) {

                    const candidatArray = list.filter(c => c !== p && (fmt.adresse(c.adressen?.find(a => a.adressart === AddressType.RECHNUNG)).includes(fmt.adresse(p.adressen?.find(a => a.adressart === AddressType.RECHNUNG)))
                        || fmt.adresse(p.adressen?.find(a => a.adressart === AddressType.RECHNUNG)).includes(fmt.adresse(c.adressen?.find(a => a.adressart === AddressType.RECHNUNG))))
                        && (p.smartDifferent ? c.smartInnovation : c.smartDifferent)
                        && p.bindungen?.filter(b => b.id.zielKunde === currentPerson.id).map(b => b.id.bindungsart).some(ba => c.bindungen?.filter(b => b.id.zielKunde === currentPerson.id).map(b => b.id.bindungsart).includes(ba))
                    );

                    if (hasElems(candidatArray)) {
                        candidat = candidatArray.find(c => c.name === p.name);
                        if (candidat !== undefined) {
                            if (p.id !== undefined && !idList.includes(p.id)) {
                                idList.push(p.id);
                            }

                            if (candidat.id !== undefined && !idList.includes(candidat.id)) {
                                idList.push(candidat.id);
                            }
                        }
                    }
                }

                if (p && candidat) {
                    return {
                        energie: p.smartDifferent ? p : candidat,
                        leasing: p.smartInnovation ? p : candidat,
                        link: '/#/mergecustomers/' + [p.id, candidat?.id].join('/'),
                    }
                }
                return undefined;
            });

            return newSuggests.filter((s): s is Suggest => !!s);
        });

    }, [handleBindingFilter]);

    const validateAnrede = (): [ValidateStatus, string] => {
        if (currentPerson.anrede?.match(/[,\s]$/)) {
            return ["error", "Ungültige Anrede. Die Anrede dürfte nicht mit einem Komma oder mit einem Leerzeichen enden."];
        }

        return ["success", ""];
    }

    const [anredeMessage, setAnredeMessage] = useState<string>("");
    const [anredeStatus, setAnredeStatus] = useState<ValidateStatus>("");

    useEffect(() => {
        const [s, m] = validateAnrede();
        setAnredeMessage(m);
        setAnredeStatus(s);
    }, [currentPerson.anrede]);

    const validateRechtsform = (): [ValidateStatus, string] => {
        if (!isNaturalPerson(currentPerson) && hasText(currentPerson.vorname)) {
            return ["error", "Bitte Vornamen prüfen!"];
        }
        return ["success", ""];
    }

    const [rechtsformMessage, setRechtsformMessage] = useState<string>("");
    const [rechtsformStatus, setRechtsformStatus] = useState<ValidateStatus>("");

    useEffect(() => {
        const [s, m] = validateRechtsform();
        setRechtsformMessage(m);
        setRechtsformStatus(s);
    }, [currentPerson.rechtsform]);

    const steps = [
        {
            title: 'Allgemein',
            content:
                <Form layout='vertical'>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Rechtsform" wrapperCol={{ span: 22 }} help={rechtsformMessage} validateStatus={rechtsformStatus}>
                                <Select
                                    showSearch
                                    value={currentPerson.rechtsform}
                                    onChange={value => {
                                        handleCustomerChange('rechtsform', value);
                                    }}
                                    filterOption={(input, option) => option !== undefined && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    options={
                                        lookup.rechtsformList.sort((a, b) => a.rechtsform.localeCompare(b.rechtsform)).map(r => ({
                                            key: r.id,
                                            label: r.rechtsform,
                                            value: r.id,
                                        }))
                                    }
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item label="Name / Firmenname" wrapperCol={{ span: 22 }} required>{

                                (editMode === EditMode.EDIT_EXISTING)

                                    // EditMode is EDIT_EXISTING
                                    ? <Input value={currentPerson.name} onChange={e => handleCustomerChange('name', e.target.value)} />

                                    // EditMode is EDIT_NEW
                                    : <AutoComplete
                                        allowClear={false}
                                        popupMatchSelectWidth={1700}
                                        value={currentPerson.name}
                                        options={customerSearchOptions()}
                                        onSearch={value => {
                                            if (hasText(value)) {
                                                setIsCustomerSearching(true);
                                                setCustomerSearchDropdownVisible(true);
                                                searchCustomerByName(value);
                                            } else {
                                                if (customerSearchAbortController) {
                                                    customerSearchAbortController.abort();
                                                    setIsCustomerSearching(false);
                                                    setCustomerSearchDropdownVisible(false);
                                                }
                                            }
                                        }}
                                        onSelect={(_value: string, option: { label: ReactNode, value?: number }) => {
                                            handleSelectedCustomerSearch(option.value!);
                                        }}
                                        open={customerSearchDropdownVisible}
                                        onInputKeyDown={e => {
                                            if (e.code === 'Escape') {
                                                e.preventDefault();
                                                setCustomerSearchDropdownVisible(false);
                                            }
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                                setCustomerSearchDropdownVisible(false);
                                            }
                                        }}
                                        notFoundContent={<Empty></Empty>}
                                        onChange={(value, _option) => handleCustomerChange('name', value)}
                                        onBlur={() => {
                                            setCustomerSearchDropdownVisible(false);
                                        }}
                                    ></AutoComplete>

                            }</Form.Item>
                            <Form.Item hidden={!isNaturalPerson(currentPerson)} label="Vorname" wrapperCol={{ span: 22 }}>
                                <Input value={currentPerson.vorname} onChange={e => handleCustomerChange('vorname', e.target.value)}></Input>
                            </Form.Item>
                            <Form.Item label="Zusatzbezeichnung" wrapperCol={{ span: 22 }}>
                                <TextArea value={currentPerson.zusatzbezeichnung} autoSize={{ minRows: 2, maxRows: 2 }} onChange={onAdditionalLabelChange}></TextArea>
                            </Form.Item>
                            <Form.Item hidden={!currentPerson.rechtsform} label="Anrede" wrapperCol={{ span: 22 }}
                                help={anredeMessage} validateStatus={anredeStatus}>
                                <Input value={currentPerson.anrede} onChange={e => handleCustomerChange('anrede', e.target.value)}></Input>
                            </Form.Item>

                            <Form.Item label="Branche" wrapperCol={{ span: 22 }}>
                                <Select
                                    mode='multiple'
                                    value={currentPerson.branche}
                                    onChange={(value) => {
                                        handleCustomerChange('branche', value);
                                    }}
                                    filterOption={(input, option) => option !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {lookup.brancheList.sort((a, b) => a.branche.localeCompare(b.branche)).map(b => <Option key={b.id} value={b.id}>{b.branche}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Relevant für / Kunde von" wrapperCol={{ span: 22 }} required>
                                <Checkbox
                                    type='checkbox'
                                    onChange={(e) => {
                                        handleCustomerChange('smartDifferent', e.target.checked)
                                    }}
                                    checked={currentPerson.smartDifferent}
                                >Energie
                                </Checkbox>
                                <Checkbox
                                    type='checkbox'
                                    onChange={(e) => {
                                        handleCustomerChange('smartInnovation', e.target.checked)
                                    }}
                                    checked={currentPerson.smartInnovation}
                                >Leasing</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item hidden={!isNaturalPerson(currentPerson)} label="Geschlecht" wrapperCol={{ span: 22 }}>
                                <Radio.Group
                                    value={currentPerson.geschlecht}
                                    onChange={e => {
                                        handleCustomerChange('geschlecht', e.target.value);
                                    }}>
                                    {
                                        lookup.geschlechtList.map(g => <Radio key={g.id} value={g.id}>{g.geschlecht}</Radio>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Row>
                                <Col span={6}>
                                    <Form.Item hidden={!isNaturalPerson(currentPerson)} label="Geburtsname" wrapperCol={{ span: 21 }}>
                                        <Input value={currentPerson.geburtsname} onChange={e => handleCustomerChange('geburtsname', e.target.value)}></Input>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item hidden={!isNaturalPerson(currentPerson)} label="Geburtsdatum" wrapperCol={{ span: 21 }}>
                                        <Input type="date" value={currentPerson.geburtsdatum} onChange={e => handleCustomerChange('geburtsdatum', e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item hidden={!isNaturalPerson(currentPerson)} label="Geburtsort" wrapperCol={{ span: 21 }}>
                                        <Input value={currentPerson.geburtsort} onChange={e => handleCustomerChange('geburtsort', e.target.value)}></Input>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item hidden={!isNaturalPerson(currentPerson)} label="Nationalität" wrapperCol={{ span: 23 }}>
                                        <Select value={currentPerson.staat} onChange={value => handleCustomerChange("staat", value)}>
                                            {
                                                [lookup.staatList.find(s => s.id === 0),
                                                lookup.staatList.find(s => s.id === 1),
                                                ...lookup.staatList.filter(s => s.id !== 0 && s.id !== 1).sort((a, b) => a.staat.localeCompare(b.staat))].map(s => <Option value={s?.id} key={s?.id}>{s?.staat}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24} hidden={isBank(currentPerson)}>
                                    <Form.Item wrapperCol={{ span: 23 }}>
                                        <Row gutter={[4, 4]} hidden={!currentPerson.smartDifferent}>
                                            <Col span={4}>
                                                <Form.Item label='Mandant'>Energie</Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item label='Kontaktvermittler'>
                                                    <SearchInput<Kunde>
                                                        allowClear
                                                        popupMatchSelectWidth={1000}
                                                        dropdownAlign={{ offset: ['-35%'] }}
                                                        value={energieVermittler}
                                                        valueProperty={'id'}
                                                        fetchDataRequest={KundenService.getAllKundenByName}
                                                        onValueChange={(value) => setEnergieVermittler(value)}
                                                        initData={energieVermittler ? [energieVermittler] : undefined}
                                                        labelFormatter={customer => fmt.kundeMitZusatz(customer)}
                                                        render={(customer) => <>{fmt.kundeMitZusatz(customer)} <MandantIcon customer={customer} /></>}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item label='Untervermittler'>
                                                    <SearchInput<Kunde>
                                                        allowClear
                                                        popupMatchSelectWidth={1000}
                                                        dropdownAlign={{ offset: ['-35%'] }}
                                                        value={energieUntervermittler}
                                                        valueProperty={'id'}
                                                        fetchDataRequest={KundenService.getAllKundenByName}
                                                        onValueChange={(value) => setEnergieUntervermittler(value)}
                                                        initData={energieUntervermittler ? [energieUntervermittler] : undefined}
                                                        labelFormatter={customer => fmt.kundeMitZusatz(customer)}
                                                        render={(customer) => <>{fmt.kundeMitZusatz(customer)} <MandantIcon customer={customer} /></>}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[4, 4]} hidden={!currentPerson.smartInnovation}>
                                            <Col span={4}>
                                                <Form.Item label={currentPerson.smartDifferent ? '' : 'Mandant'}>Leasing</Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item label={currentPerson.smartDifferent ? '' : 'Kontaktvermittler'}>
                                                    <SearchInput<Kunde>
                                                        allowClear
                                                        popupMatchSelectWidth={1000}
                                                        dropdownAlign={{ offset: ['-35%'] }}
                                                        value={leasingVermittler}
                                                        valueProperty={'id'}
                                                        fetchDataRequest={KundenService.getAllKundenByName}
                                                        onValueChange={(value) => setLeasingVermittler(value)}
                                                        initData={leasingVermittler ? [leasingVermittler] : undefined}
                                                        labelFormatter={customer => fmt.kundeMitZusatz(customer)}
                                                        render={(customer) => <>{fmt.kundeMitZusatz(customer)} <MandantIcon customer={customer} /></>}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item label={currentPerson.smartDifferent ? '' : 'Untervermittler'}>
                                                    <SearchInput<Kunde>
                                                        allowClear
                                                        popupMatchSelectWidth={1000}
                                                        dropdownAlign={{ offset: ['-35%'] }}
                                                        value={leasingUntervermittler}
                                                        valueProperty={'id'}
                                                        fetchDataRequest={KundenService.getAllKundenByName}
                                                        onValueChange={(value) => setEnergieUntervermittler(value)}
                                                        initData={leasingUntervermittler ? [leasingUntervermittler] : undefined}
                                                        labelFormatter={customer => fmt.kundeMitZusatz(customer)}
                                                        render={(customer) => <>{fmt.kundeMitZusatz(customer)} <MandantIcon customer={customer} /></>}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hidden={isMissing(currentPerson.rechtsform) || isBank(currentPerson)} label="Ust - ID" wrapperCol={{ span: 22 }}>
                                        <Input value={currentPerson.ustid} onChange={(event) => handleCustomerChange("ustid", event.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hidden={isMissing(currentPerson.rechtsform) || isBank(currentPerson)} label="Steuernummer" wrapperCol={{ span: 22 }}>
                                        <Input value={currentPerson.steuernr} onChange={(event) => handleCustomerChange("steuernr", event.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item hidden={isMissing(currentPerson.rechtsform) || isBank(currentPerson)} label="Kennzeichnung" wrapperCol={{ span: 22 }}>
                                        <Select value={currentPerson.kennzeichnungen?.[0]}
                                            onChange={(value) => {
                                                const kennzeichnungen = [value];
                                                handleCustomerChange("kennzeichnungen", kennzeichnungen);
                                            }}>
                                            {lookup.kennzeichnungList.map((k, idx) =>
                                                <Option key={idx}
                                                    value={k.id}>
                                                    <div style={{
                                                        height: 13,
                                                        width: 13,
                                                        border: '1px solid black',
                                                        background: k.color,
                                                        borderRadius: '50%',
                                                        display: "inline-block",
                                                        marginBottom: -2,
                                                    }} /> {k.kennzeichnung}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item hidden={isMissing(currentPerson.rechtsform) || isBank(currentPerson)} label="Agentur" wrapperCol={{ span: 22 }}>
                                        <Select value={currentPerson.agenturen?.[0]} onChange={(value) => { const agenturen = [value]; handleCustomerChange("agenturen", agenturen); }}>
                                            {lookup.agenturList.sort((a, b) => a.agentur.localeCompare(b.agentur)).map(a => <Option key={a.id} value={a.id}>{a.agentur}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={23}>
                                    <Form.Item hidden={isMissing(currentPerson.rechtsform)} label="Funktion" wrapperCol={{ span: 24 }}>
                                        <Select
                                            mode='multiple'
                                            value={currentPerson.funktion}
                                            onChange={(value) => {
                                                if (!value.includes(BANK_FUNCTION)) {
                                                    handleCustomerChange('bank', undefined);
                                                }
                                                handleCustomerChange('funktion', value);
                                            }}
                                            filterOption={(input, option) => option !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {lookup.funktionList.sort((a, b) => a.funktion.localeCompare(b.funktion)).map(f => <Option key={f.id} value={f.id}>{f.funktion}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item hidden={!hasRechtsform(currentPerson) || isNaturalPerson(currentPerson)} label="Handelregister" wrapperCol={{ span: 22 }}>
                                        <Input value={currentPerson.handelsregister} onChange={(event) => handleCustomerChange("handelsregister", event.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item hidden={!hasRechtsform(currentPerson) || isNaturalPerson(currentPerson)} label="HR Nummer" wrapperCol={{ span: 22 }}>
                                        <Input value={currentPerson.hrnr} onChange={(event) => handleCustomerChange("hrnr", event.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item hidden={!hasRechtsform(currentPerson) || isNaturalPerson(currentPerson)} label="Gründungsdatum" wrapperCol={{ span: 22 }}>
                                        <Input type="date" value={currentPerson.geburtsdatum} onChange={e => handleCustomerChange('geburtsdatum', e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item hidden={!hasRechtsform(currentPerson) || isNaturalPerson(currentPerson)} label="Nationalität" wrapperCol={{ span: 22 }}>
                                        <Select value={currentPerson.staat} onChange={value => handleCustomerChange("staat", value)}>
                                            {
                                                [lookup.staatList.find(s => s.id === 0),
                                                lookup.staatList.find(s => s.id === 1),
                                                ...lookup.staatList.filter(s => s.id !== 0 && s.id !== 1).sort((a, b) => a.staat.localeCompare(b.staat))].map(s => <Option value={s?.id} key={s?.id}>{s?.staat}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hidden={!isBank(currentPerson)} label="BLZ" wrapperCol={{ span: 22 }}>
                                        <Input value={currentPerson.bank?.blz}
                                            onChange={(event) => {
                                                let bank = Bank.DEFAULT();
                                                bank.id = currentPerson.bank?.id;
                                                bank.blz = event.target.value;
                                                bank.bicswift = currentPerson.bank?.bicswift;
                                                handleCustomerChange("bank", bank);
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item hidden={!isBank(currentPerson)} label="BIC/SWIFT" wrapperCol={{ span: 22 }}>
                                        <Input value={currentPerson.bank?.bicswift}
                                            onChange={(event) => {
                                                let bank = Bank.DEFAULT();
                                                bank.id = currentPerson.bank?.id;
                                                bank.blz = currentPerson.bank?.blz;
                                                bank.bicswift = event.target.value;
                                                handleCustomerChange("bank", bank);
                                            }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>,
        },
        {
            title: 'Adressen',
            content: <>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Row style={{ marginBottom: 5 }} >
                            <Col span={24}>
                                Adresse anlegen: <Button icon={<EnvironmentTwoTone twoToneColor={'red'} />} onClick={() => {
                                    setAddress(KundenAdresse.DEFAULT());
                                    setAddressIndex(addressData.length);
                                    setEditAddressMode(EditMode.EDIT_NEW);
                                    setIsAddressModalVisible(true);
                                }} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 5 }} >
                            <Col span={24} hidden={!addressWarningVisible}>
                                <Alert
                                    message={addressWarning}
                                    type="warning"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table bordered dataSource={!isLookUpLoading ? handleAddressFilter() : []}
                                    showSorterTooltip={false}
                                    columns={[{
                                        dataIndex: 'strasse',
                                        sorter: { compare: compareByKey(a => a.strasse ?? ''), multiple: 1 },
                                        title: 'Straße',
                                        children: [{
                                            dataIndex: 'strasse',
                                            key: 'strasse',
                                            title:
                                                <Input placeholder='Filter Straße'
                                                    value={addressStreetField}
                                                    onChange={e => { setAddressStreetField(e.target.value); }}
                                                />,
                                        }],
                                    },
                                    {
                                        dataIndex: 'hausnr',
                                        title: 'Hausnr',
                                        sorter: { compare: compareByKey(a => a.hausnr ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'hausnr',
                                            key: 'hausnr',
                                            title:
                                                <Input placeholder='Filter Hausnummer'
                                                    value={addressNumberField}
                                                    onChange={e => { setAddressNumberField(e.target.value); }}
                                                />,
                                        }],
                                    },
                                    {
                                        dataIndex: 'plz',
                                        title: 'Postleitzahl',
                                        sorter: { compare: compareByKey(a => a.plz ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'plz',
                                            key: 'postleitzahl',
                                            title:
                                                <Input placeholder='Filter PLZ'
                                                    value={addressZipcodeField}
                                                    onChange={e => { setAddressZipcodeField(e.target.value); }}
                                                />,
                                        }],
                                    },
                                    {
                                        dataIndex: 'ort',
                                        title: 'Ort',
                                        sorter: { compare: compareByKey(a => a.ort ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'ort',
                                            key: 'ort',
                                            title:
                                                <Input placeholder='Filter Ort'
                                                    value={addressLocationField}
                                                    onChange={e => { setAddressLocationField(e.target.value); }}
                                                />,
                                        }],
                                    },
                                    {
                                        dataIndex: 'ortsteil',
                                        title: 'Ortsteil',
                                        sorter: { compare: compareByKey(a => a.ortsteil ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'ortsteil',
                                            key: 'ortsteil',
                                            title:
                                                <Input placeholder='Filter Ortsteil'
                                                    value={addressDistrictField}
                                                    onChange={e => { setAddressDistrictField(e.target.value); }}
                                                />,
                                        }],
                                    },
                                    {
                                        dataIndex: 'land',
                                        title: 'Land',
                                        sorter: { compare: compareByKey(a => a.land ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'land',
                                            key: 'land',
                                            title:
                                                <Input placeholder='Filter Land'
                                                    value={addressCountryField}
                                                    onChange={e => { setAddressCountryField(e.target.value); }}
                                                />,
                                        }],
                                    },
                                    {
                                        dataIndex: 'adressart',
                                        title: 'Art',
                                        sorter: { compare: compareByKey(a => lookup.adressartList.find(a1 => a1.id === a.adressart)?.adressart ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'adressart',
                                            key: 'adressart',
                                            title:
                                                <Select placeholder='Filter Adressart'
                                                    value={addressTypeField}
                                                    options={lookup.adressartList.map(a => {
                                                        return { label: a.adressart, value: a.id };
                                                    })}
                                                    onChange={value => { setAddressTypeField(value); }}
                                                />,
                                            render: (value: KundenAdresse['adressart']) => lookup.adressartList.find(a => a.id === value)?.adressart,
                                        }],
                                    },
                                    {
                                        width: 80,
                                        dataIndex: 'edit',
                                        title: 'Aktion',
                                        children: [
                                            {
                                                key: 0,
                                                title:
                                                    <Row gutter={[8, 8]} wrap={false}>
                                                        <Col><Button title="zurücksetzen" onClick={resetAddressFilter} icon={<Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image>}></Button></Col>
                                                    </Row>,
                                                render: (_: unknown, record, index) =>
                                                    <Row wrap={false} gutter={[8, 8]}>
                                                        <Col ><Button title='Bearbeiten' icon={<EditOutlined style={{ color: 'steelblue' }} />} key={index} onClick={() => {
                                                            setEditAddressMode(EditMode.EDIT_EXISTING);
                                                            setAddress(record);
                                                            setAddressIndex(addressData.findIndex(v => v === record));
                                                            setIsAddressModalVisible(true);
                                                        }}></Button>
                                                        </Col>
                                                        <Col ><Button title='Löschen' icon={<DeleteOutlined style={{ color: 'red' }} />} danger={true} onClick={() => {
                                                            setConfirmDeleteModal({
                                                                show: true,
                                                                indexRemove: index,
                                                                message: 'Löschen von der Adresse vormerken?',
                                                                onDelete: () => handleAddressDelete(record)
                                                            })
                                                        }}></Button></Col>
                                                    </Row>
                                            }],
                                    }
                                    ]}>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        },
        {
            title: 'Bankverbindungen',
            content: <>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Row style={{ marginBottom: 5 }}>
                            <Col span={24}>
                                Bankverbindung anlegen: <Button icon={<CreditCardTwoTone twoToneColor={'red'} />} onClick={() => {
                                    setBankverbindung(Bankverbindung.DEFAULT());
                                    setBankverbindungIndex(bankAccountData.length);
                                    showBankverbindungModal(Bankverbindung.DEFAULT(), EditMode.EDIT_NEW);
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table bordered dataSource={!isLookUpLoading ? handleBankAccountFilter() : []}
                                    showSorterTooltip={false}
                                    columns={[
                                        {
                                            dataIndex: 'bank',
                                            title: 'Bank',
                                            sorter: { compare: compareByKey(b => lookupBanks.find(b1 => b1.id === b.bank)?.name ?? ''), multiple: 1 },
                                            children: [{
                                                dataIndex: 'bank',
                                                key: 'bank',
                                                title: <Input placeholder='Filter Bank'
                                                    value={bankAccountBankField}
                                                    onChange={e => { setBankAccountBankField(e.target.value) }}
                                                />,
                                                render: (value: Bankverbindung['bank']) => {
                                                    const bank = lookupBanks.find(b => b.id === value);
                                                    if (bank !== undefined) {
                                                        return [bank.name, [bank.adressen?.at(0)?.strasse, bank.adressen?.at(0)?.hausnr].filter(hasText).join(' '), bank.adressen?.at(0)?.plz, bank.adressen?.at(0)?.ort]
                                                            .filter(hasText).join(', ')
                                                    }
                                                    return '';
                                                },
                                            }]
                                        },
                                        {
                                            dataIndex: 'iban',
                                            title: 'IBAN',
                                            sorter: { compare: compareByKey(b => b.iban ?? ''), multiple: 1 },
                                            children: [{
                                                dataIndex: 'iban',
                                                key: 'iban',
                                                title: <Input placeholder='Filter IBAN'
                                                    value={bankAccountIbanField}
                                                    onChange={e => { setBankAccountIbanField(e.target.value) }}
                                                />,
                                                render: (value: Bankverbindung['iban']) => fmt.iban.print(value),
                                            }],
                                        },
                                        {
                                            dataIndex: 'bic',
                                            title: 'BIC / SWIFT',
                                            sorter: { compare: compareByKey(b => b.bic ?? ''), multiple: 1 },
                                            children: [{
                                                dataIndex: 'bic',
                                                key: 'bic',
                                                title: <Input placeholder='Filter BIC / SWIFT'
                                                    value={bankAccountBicField}
                                                    onChange={e => { setBankAccountBicField(e.target.value) }}
                                                />,
                                            }],
                                        },
                                        {
                                            dataIndex: 'konto',
                                            title: 'Kontonummer',
                                            sorter: { compare: compareByKey(b => b.konto ?? ''), multiple: 1 },
                                            children: [{
                                                dataIndex: 'konto',
                                                key: 'konto',
                                                title: <Input placeholder='Filter Konto'
                                                    value={bankAccountAccountNumberField}
                                                    onChange={e => { setBankAccountAccountNumberField(e.target.value) }}
                                                />,
                                                render: (value: Bankverbindung['konto']) => fmt.konto.print(value),
                                            }],
                                        },
                                        {
                                            dataIndex: 'blz',
                                            title: 'Bankleitzahl',
                                            sorter: { compare: compareByKey(b => b.blz ?? ''), multiple: 1 },
                                            children: [{
                                                dataIndex: 'blz',
                                                key: 'blz',
                                                title: <Input placeholder='Filter BLZ'
                                                    value={bankAccountBankCodeField}
                                                    onChange={e => { setBankAccountBankCodeField(e.target.value) }}
                                                />,
                                                render: (value: Bankverbindung['blz']) => fmt.blz.print(value),
                                            }],
                                        },
                                        {
                                            dataIndex: 'kommentar',
                                            title: 'Kommentar',
                                            children: [{
                                                dataIndex: 'kommentar',
                                                key: 'kommentar',
                                                title: <Input placeholder='Filter Kommentar'
                                                    value={bankAccountCommentField}
                                                    onChange={e => { setBankAccountCommentField(e.target.value) }}
                                                />,
                                            }],
                                        },
                                        {
                                            dataIndex: 'erstellungszeitpunkt',
                                            title: 'Erstellungszeitpunkt',
                                            children: [{
                                                dataIndex: 'erstellungszeitpunkt',
                                                key: 'erstellungszeitpunkt',
                                                render: value => fmt.zeitpunkt(value),
                                                title: <Input placeholder='Filter Erstellungszeitpunkt'
                                                    value={bankAccountErstellungszeitpunktField}
                                                    onChange={e => { setBankAccountErstellungsZeitpunktField(e.target.value) }} />
                                            }],
                                        },
                                        {
                                            dataIndex: 'anpassungszeitpunkt',
                                            title: 'Anpassungszeitpunkt',
                                            children: [{
                                                dataIndex: 'anpassungszeitpunkt',
                                                key: 'anpassungszeitpunkt',
                                                render: value => fmt.zeitpunkt(value),
                                                title: <Input placeholder='Filter Anpassungszeitpunkt'
                                                    value={bankAccountAnpassungszeitpunktField}
                                                    onChange={e => { setBankAccountAnpassungsZeitpunktField(e.target.value) }} />
                                            }],
                                        },
                                        {
                                            width: 80,
                                            dataIndex: 'edit',
                                            title: 'Aktion',
                                            children: [
                                                {
                                                    key: 0,
                                                    title:
                                                        <Row gutter={[8, 8]} wrap={false}>
                                                            <Col><Button title="zurücksetzen" icon={<Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image>} onClick={resetBankAccountFilter}></Button></Col>
                                                        </Row>,
                                                    render: (_: unknown, record: Bankverbindung, index: number) =>
                                                        <Row wrap={false} gutter={[8, 8]}>
                                                            <Col>
                                                                <Button
                                                                    title='Bearbeiten'
                                                                    icon={<EditOutlined style={{ color: 'steelblue' }} />}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setEditBankverbindungMode(EditMode.EDIT_EXISTING);
                                                                        setBankverbindung(record);
                                                                        showBankverbindungModal(record, EditMode.EDIT_EXISTING)
                                                                        setBankverbindungIndex(bankAccountData.findIndex(v => v === record));
                                                                    }} />
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    title='Löschen'
                                                                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                                                                    danger={true}
                                                                    onClick={() => {
                                                                        setConfirmDeleteModal({
                                                                            show: true,
                                                                            indexRemove: index,
                                                                            message: 'Löschen von der Bankverbindung vormerken?',
                                                                            onDelete: () => handleBankverbindungDelete(record)
                                                                        })
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                }],
                                        }
                                    ]}>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </>
        },
        {
            title: 'Kontakt | Ansprechpartner',
            content: <>
                <Row>
                    <Col span={24}>
                        <Divider orientation='left'>Kontakt</Divider>
                        <Row style={{ marginBottom: 5 }}>
                            <Col span={24}>
                                Kontaktdaten anlegen: <Button icon={<ContactsTwoTone twoToneColor={'red'} />} onClick={() => {
                                    setContact(Kontakt.DEFAULT());
                                    setContactIndex(contactData.length);
                                    setEditContactMode(EditMode.EDIT_NEW);
                                    setContactModalVisible(true);
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table bordered dataSource={!isLookUpLoading ? handleContactFilter() : []}
                                    showSorterTooltip={false}
                                    columns={[{
                                        title: 'Art',
                                        dataIndex: 'kontaktartId',
                                        sorter: { compare: compareByKey(k => ((lookup.kontaktartList.find(k1 => k1.id === k.kontaktartId)?.kontaktart ?? ''))), multiple: 1 },
                                        children: [{
                                            dataIndex: 'kontaktartId',
                                            key: 'kontaktartId',
                                            title: <Select style={{ width: '100%' }} placeholder='Filter Kontartart'
                                                value={contactTypeField}
                                                options={lookup.kontaktartList.map(k => {
                                                    return { label: k.kontaktart, value: k.id }
                                                })}
                                                onChange={value => { setContactTypeField(value) }}></Select>,
                                            render: (value: Kontakt['kontaktartId']) => lookup.kontaktartList.find(ka => ka.id === value)?.kontaktart ?? 'unbekannt',
                                        }],
                                    },
                                    {
                                        title: 'Inhalt',
                                        dataIndex: 'inhalt',
                                        sorter: { compare: compareByKey(k => k.inhalt ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'inhalt',
                                            key: 'inhalt',
                                            title: <Input placeholder='Filter Inhalt'
                                                value={contactContentField}
                                                onChange={e => { setContactContentField(e.target.value) }}></Input>,
                                            render: (_value: Kontakt['inhalt'], record: Kontakt, _index: number) =>
                                                <KontaktInhalt kontakt={record} landcode={getRegionCode(currentPerson.adressen?.find(a => a.adressart === AddressType.RECHNUNG))}></KontaktInhalt>
                                        }],
                                    },
                                    {
                                        title: 'Bezeichnung',
                                        dataIndex: 'bezeichnung',
                                        sorter: { compare: compareByKey(k => k.bezeichnung ?? ''), multiple: 1 },
                                        children: [{
                                            dataIndex: 'bezeichnung',
                                            key: 'bezeichnung',
                                            title: <Input placeholder='Filter Bezeichnung'
                                                value={contactDescriptionField}
                                                onChange={e => { setContactDescriptionField(e.target.value) }}></Input>,
                                        }],
                                    },
                                    {
                                        width: 80,
                                        dataIndex: 'edit',
                                        title: 'Aktion',
                                        children: [
                                            {
                                                key: 0,
                                                title:
                                                    <Row gutter={[8, 8]} wrap={false}>
                                                        <Col><Button title="zurücksetzen" icon={<Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image>} onClick={resetContactFilter}></Button></Col>
                                                    </Row>,
                                                render: (_: unknown, record: Kontakt, index: number) => {
                                                    return <Row wrap={false} gutter={[8, 8]}>
                                                        <Col>
                                                            <Button
                                                                title='Bearbeiten'
                                                                icon={<EditOutlined style={{ color: 'steelblue' }} />}
                                                                key={index}
                                                                onClick={() => {
                                                                    setContact(record);
                                                                    setEditContactMode(EditMode.EDIT_EXISTING);
                                                                    setContactIndex(contactData.findIndex(v => v === record));
                                                                    setContactModalVisible(true);
                                                                }} />
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                title='Löschen'
                                                                icon={<DeleteOutlined style={{ color: 'red' }} />}
                                                                danger={true}
                                                                onClick={() => {
                                                                    setConfirmDeleteModal({
                                                                        show: true,
                                                                        indexRemove: index,
                                                                        message: `Löschen dieses Kontakts: ${<Row>
                                                                            <Col span={6}>{lookup.kontaktartList.find(ka => ka.id === record.kontaktartId)?.kontaktart}: </Col>
                                                                            <Col span={10}><KontaktInhalt kontakt={record} /></Col>
                                                                            <Col span={8}>{record.bezeichnung}</Col>
                                                                        </Row>} vormerken?`,
                                                                        onDelete: () => handleContactsDelete(record)
                                                                    })
                                                                }} />
                                                        </Col>
                                                    </Row>
                                                }
                                            }],
                                    }
                                    ]}></Table>
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <Row>
                    <Col span={24}>
                        <Divider orientation='left'>Ansprechpartner, Bindungen</Divider>
                        <Row style={{ marginBottom: 5 }}>
                            <Col>
                                Neue Bindung anlegen: <Button icon={<UserAddOutlined style={{ color: 'steelblue' }} />} type='default' onClick={() => {
                                    showCurrentAssociatedPersonModal(undefined, Kunde.DEFAULT());
                                }}></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table bordered
                                    dataSource={(!isBindungLoading && !isLookUpLoading)
                                        ? FrontendId.keys(handleBindingFilter()).map(idx => associatedPersons[idx]).filter(person => !person.deleteDate)
                                        : []}
                                    showSorterTooltip={false}
                                    columns={[
                                        {
                                            title: 'Name',
                                            dataIndex: 'name',
                                            sorter: { compare: compareByKey(bi => bi.name ?? ''), multiple: 1 },
                                            width: 150,
                                            children: [{
                                                dataIndex: 'name',
                                                key: 'name',
                                                title: <Input placeholder='Filter Name'
                                                    value={bindingSurnameField}
                                                    onChange={e => { setBindingSurnameField(e.target.value) }}></Input>,
                                                render: (value: Kunde['name'], record: Kunde) => (record.id !== undefined ? <Link to={`/customer/${record.id}`} target={'_blank'}>{value}</Link> : value),
                                            }],
                                        },
                                        {
                                            title: 'Vorname',
                                            dataIndex: 'vorname',
                                            sorter: { compare: compareByKey(bi => bi.vorname ?? ''), multiple: 1 },
                                            width: 150,
                                            children: [{
                                                dataIndex: 'vorname',
                                                key: 'vorname',
                                                title: <Input placeholder='Filter Vorname'
                                                    value={bindingForenameField}
                                                    onChange={e => { setBindingForenameField(e.target.value) }}></Input>,
                                            }],
                                        },
                                        {
                                            title: 'Zusatzbezeichnung',
                                            dataIndex: 'zusatzbezeichnung',
                                            sorter: { compare: compareByKey(bi => bi.zusatzbezeichnung ?? ''), multiple: 1 },
                                            width: 100,
                                            children: [{
                                                dataIndex: 'zusatzbezeichnung',
                                                key: 'zusatzbezeichung',
                                                title: <Input placeholder='Filter Zusatzbezeichnung'
                                                    value={bindingAdditionalField}
                                                    onChange={e => { setBindingAdditionalField(e.target.value) }}></Input>,
                                            }],
                                        },
                                        {
                                            title: 'Bindungen',
                                            dataIndex: 'bindungen',
                                            width: 150,
                                            children: [{
                                                dataIndex: 'bindungen',
                                                key: 'bindungen',
                                                title: <Select style={{ width: '100%' }} mode='multiple' placeholder='Filter Bindungen'
                                                    value={bindingTypeField}
                                                    filterOption={(input, option) => option?.props.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={value => { setBindingTypeField(value) }}>{lookup.bindungsartList.map(b => {
                                                        return <Option key={b.id} value={b.id}>{b.bindungsart}</Option>
                                                    })}</Select>,
                                                render: (_value: Kunde['bindungen'], record: Kunde) =>
                                                    bindungen[BindungId.fromKunden(currentPerson, record)]
                                                        ?.map(v => lookup.bindungsartList.find(ba => ba.id === v.id.bindungsart)?.bindungsart)
                                                        ?.join(', '),
                                            }],
                                        },
                                        {
                                            title: 'Postion/Details',
                                            dataIndex: 'details',
                                            sorter: { compare: compareByKey(bi => bi['bindungen']?.map(b => b.details).join(',') ?? ''), multiple: 1 },
                                            width: 100,
                                            children: [{
                                                dataIndex: 'details',
                                                key: 'details',
                                                title: <Input placeholder='Filter Position/Details'
                                                    value={bindingDetailsField}
                                                    onChange={e => { setBindingDetailsField(e.target.value) }}></Input>,
                                                render: (_value, record: Kunde) => {
                                                    return bindungen[BindungId.fromKunden(currentPerson, record)]
                                                        ?.map(v => v.details).filter(s => hasText(s))
                                                        ?.join(', ');
                                                }
                                            }],
                                        },
                                        {
                                            title: 'Kontakt',
                                            dataIndex: 'kontakte',
                                            width: 300,
                                            children: [{
                                                dataIndex: 'kontakte',
                                                key: 'kontakte',
                                                title: <Input placeholder='Filter Kontakt'
                                                    value={bindingContactsField}
                                                    onChange={e => { setBindingContactsField(e.target.value) }}></Input>,
                                                render: (value: Kunde['kontakte']) => {
                                                    return value?.map(v =>
                                                        <Row>
                                                            <Col span={6}>{lookup.kontaktartList.find(ka => ka.id === v.kontaktartId)?.kontaktart}: </Col>
                                                            <Col span={10}><KontaktInhalt kontakt={v} /></Col>
                                                            <Col span={8}>{v.bezeichnung}</Col>
                                                        </Row>)
                                                },
                                            }],
                                        },
                                        {
                                            width: 80,
                                            dataIndex: 'edit',
                                            title: 'Aktion',
                                            children: [
                                                {
                                                    key: 0,
                                                    title:
                                                        <Row gutter={[8, 8]} wrap={false}>
                                                            <Col><Button title="zurücksetzen" icon={<Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image>} onClick={resetBindingFilter}></Button></Col>
                                                        </Row>,
                                                    render: (_: unknown, record: Kunde, index: number) => {
                                                        return <Row wrap={false} gutter={[8, 8]}>
                                                            <Col><Button title='Bearbeiten' icon={<EditOutlined style={{ color: 'steelblue' }} />} onClick={() => {
                                                                showCurrentAssociatedPersonModal(index, record);
                                                            }}></Button></Col>
                                                            <Col><Button title='Löschen' icon={<DeleteOutlined style={{ color: 'red' }} />} danger={true} onClick={() => {
                                                                setConfirmDeleteModal({
                                                                    show: true,
                                                                    indexRemove: index,
                                                                    message: 'Löschen der Bindungen zu "' + fmt.kunde(record) + '" vormerken?',
                                                                    onDelete: handleAssociatedPersonDelete
                                                                })
                                                            }}></Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                }],
                                        }
                                    ]}
                                >
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} hidden={noElems(suggests)}>
                                <Divider orientation='left'>Vorschläge für Zusammenführung aus Bindungen</Divider>
                                <Collapse defaultActiveKey={['1']} ghost>
                                    <Panel header={''} key="1">
                                        <Table bordered size='small'
                                            dataSource={(!isBindungLoading && !isLookUpLoading)
                                                ? suggests
                                                : []}
                                            showSorterTooltip={false}
                                            columns={[
                                                {
                                                    title: 'Energie Person',
                                                    dataIndex: 'energie',
                                                    render: (value: Kunde) => {
                                                        return <>
                                                            <Row>{fmt.kunde(value)}</Row>
                                                            <Row>{fmt.adresse(value.adressen?.find(a => a.adressart === AddressType.RECHNUNG))}</Row>
                                                        </>
                                                    },
                                                },
                                                {
                                                    title: 'Leasing Person',
                                                    dataIndex: 'leasing',
                                                    render: (value: Kunde) => {
                                                        return <>
                                                            <Row>{fmt.kunde(value)}</Row>
                                                            <Row>{fmt.adresse(value.adressen?.find(a => a.adressart === AddressType.RECHNUNG))}</Row>
                                                        </>
                                                    },
                                                },
                                                {
                                                    title: 'Merge Link',
                                                    dataIndex: 'link',
                                                    render: (value: string) => {
                                                        return <Button ghost type='primary' href={value} target='_blank' icon={<MergeCellsOutlined />}></Button>
                                                    },
                                                }
                                            ]} />
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        },
        {
            title: 'Zusammenfassung | Speichern',
            content: <>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                Name / Firmenname: {currentPerson.name}
                            </Col>
                            <Col span={24} hidden={!isNaturalPerson(currentPerson)}>
                                Vorname: {currentPerson.vorname}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.zusatzbezeichnung)}>
                                Zusatzbezeichnung: {currentPerson.zusatzbezeichnung}
                            </Col>
                            <Col span={24} hidden={!hasRechtsform(currentPerson)}>
                                Rechtsform: {isLookUpLoading ? '...' : lookup.rechtsformList.find(rf => rf.id === currentPerson.rechtsform)?.rechtsform ?? ''}
                            </Col>
                            <Col span={24} hidden={noElems(currentPerson.branche)}>
                                Branche: {isLookUpLoading ? '...' : currentPerson.branche?.map(brId => lookup.brancheList.find(b => b.id === brId)?.branche).join(', ') ?? ''}
                            </Col>
                            <Col span={24} hidden={isMissing(currentPerson.geschlecht)}>
                                Geschlecht: {isLookUpLoading ? '...' : lookup.geschlechtList.find(g => g.id === currentPerson.geschlecht)?.geschlecht ?? 'unbekannt'}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.anrede)}>
                                Anrede: {currentPerson.anrede}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.geburtsname)}>
                                Geburtsname: {currentPerson.geburtsname}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.geburtsdatum)}>
                                {isNaturalPerson(currentPerson) ? 'Geburtsdatum' : 'Gründungsdatum'} : {currentPerson.geburtsdatum}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.geburtsort)}>
                                Geburtsort: {currentPerson.geburtsort}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.ustid)}>
                                Ust - ID: {currentPerson.ustid}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.steuernr)}>
                                Steuernummer: {currentPerson.steuernr}
                            </Col>
                            <Col span={24} hidden={noElems(currentPerson.kennzeichnungen)}>
                                Kennzeichnung: {isLookUpLoading ? '...' : currentPerson.kennzeichnungen?.map(kId => lookup.kennzeichnungList.find(k => k.id === kId)?.kennzeichnung).join(', ') ?? ''}
                            </Col>
                            <Col span={24} hidden={noElems(currentPerson.agenturen)}>
                                Agentur: {isLookUpLoading ? '...' : lookup.agenturList.find(a => a.id === currentPerson.agenturen?.[0])?.agentur ?? 'unbekannt'}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.handelsregister)}>
                                Handelsregister: {currentPerson.handelsregister}
                            </Col>
                            <Col span={24} hidden={noText(currentPerson.hrnr)}>
                                HR-Nummer: {currentPerson.hrnr}
                            </Col>
                            <Col span={24} hidden={isMissing(currentPerson.funktion)}>
                                Funktionen: {isLookUpLoading ? '...' : currentPerson.funktion?.map(f => lookup.funktionList.find(lf => lf.id === f)).filter(isPresent).map(d => d.funktion).join(', ') ?? ''}
                            </Col>
                            <Col span={24} hidden={noElems(currentPerson.vermittler)}>
                                <Divider orientation='left'>Vermittler</Divider>
                                <Table bordered dataSource={vermittler}
                                    showSorterTooltip={false}
                                    columns={[
                                        {
                                            title: 'Mandant',
                                            dataIndex: 'id',
                                            render: (value: Vermittler['id'], _record: Vermittler, _index: number) =>
                                                value?.mandant ? 'Leasing' : 'Energie'
                                        },
                                        {
                                            title: 'Kontaktvermittler',
                                            dataIndex: 'vermittler',
                                            render: (_value: Vermittler['vermittler'], record: Vermittler, _index: number) =>
                                                record.id?.mandant ? fmt.vermittler(leasingVermittler) : fmt.vermittler(energieVermittler)
                                        },
                                        {
                                            title: 'Untervermittler',
                                            dataIndex: 'untervermittler',
                                            render: (_value: Vermittler['untervermittler'], record: Vermittler, _index: number) =>
                                                record.id?.mandant ? fmt.vermittler(leasingUntervermittler) : fmt.vermittler(leasingUntervermittler)
                                        },
                                    ]}></Table>
                            </Col>
                            <Col span={24}>
                                <Collapse defaultActiveKey={[]} ghost expandIconPosition='end'>
                                    <Panel header="Bindungen:" key="1">
                                        <Table bordered size='small'
                                            dataSource={FrontendId.keys(associatedPersons).map(idx => associatedPersons[idx])}
                                            showSorterTooltip={false}
                                            columns={[
                                                {
                                                    title: 'Vorname',
                                                    dataIndex: 'vorname'
                                                },
                                                {
                                                    title: 'Name',
                                                    dataIndex: 'name'
                                                },
                                                {
                                                    title: 'Bindungen',
                                                    dataIndex: 'bindungen',
                                                    render: (_value: Kunde['bindungen'], record: Kunde) =>
                                                        bindungen[BindungId.fromKunden(record, currentPerson)]?.map(v => lookup.bindungsartList.find(ba => ba.id === v.id.bindungsart)?.bindungsart)?.join(', ')
                                                },
                                            ]}
                                        >
                                        </Table>
                                    </Panel>
                                    <Panel header="Adressen:" key="2">
                                        <Table bordered size='small' dataSource={addressData}
                                            showSorterTooltip={false}
                                            columns={[
                                                { title: 'Straße', dataIndex: 'strasse' },
                                                { title: 'Hausnr', dataIndex: 'hausnr' },
                                                { title: 'PLZ', dataIndex: 'plz' },
                                                { title: 'Ort', dataIndex: 'ort' },
                                                { title: 'Ortsteil', dataIndex: 'ortsteil' },
                                                { title: 'Land', dataIndex: 'land' }
                                            ]} />
                                    </Panel>
                                    <Panel header="Bankverbindungen:" key="3">
                                        <Table bordered size='small' dataSource={bankAccountData}
                                            showSorterTooltip={false}
                                            columns={[
                                                {
                                                    title: 'Bank',
                                                    dataIndex: 'bank',
                                                    render: (value: Bankverbindung['bank']) => {
                                                        const bank = lookupBanks.find(b => b.id === value);
                                                        if (bank !== undefined) {
                                                            return [bank.name, [bank.adressen?.at(0)?.strasse, bank.adressen?.at(0)?.hausnr].filter(s => s && s !== '').join(' '), bank.adressen?.at(0)?.plz, bank.adressen?.at(0)?.ort]
                                                                .filter(s => s && s !== '').join(', ');
                                                        }
                                                        return '';
                                                    }
                                                },
                                                {
                                                    title: 'IBAN',
                                                    dataIndex: 'iban',
                                                    render: (value: Bankverbindung['iban']) => fmt.iban.print(value),
                                                },
                                                {
                                                    title: 'BIC / SWIFT',
                                                    dataIndex: 'bic'
                                                },
                                                {
                                                    title: 'Kontonummer',
                                                    dataIndex: 'konto',
                                                    render: (value: Bankverbindung['konto']) => fmt.konto.print(value),
                                                },
                                                {
                                                    title: 'Bankleitzahl',
                                                    dataIndex: 'blz',
                                                    render: (value: Bankverbindung['blz']) => fmt.blz.print(value),
                                                },
                                                {
                                                    title: 'Kommentar',
                                                    dataIndex: 'kommentar',
                                                },
                                            ]} />
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        }
    ];

    const next = () => {
        if (currentStep === 0 && currentPerson.name === '') {
            message.warning(noNameWarningMessage);
        } else {
            if (!(currentPerson.smartDifferent || currentPerson.smartInnovation)) {
                message.warning('Wessen Kunde ist die Person?');
            } else {
                setCurrentStep(currentStep + 1);
            }
        }
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const isDirty = () => {
        const dirty = notSaved
            || addressData.some(a => !a.id)
            || addressData.filter(a => a.adressart === AddressType.RECHNUNG).length > 1
            || bankAccountData.some(b => !b.id)
            || contactData.some(c => !c.id)
            || FrontendId.keys(associatedPersons).some(k => !associatedPersons[k].id)
            || BindungId.keys(bindungen).some(k => k.includes('@'))
            || Object.keys(deletedBindungen).length > 0
        return dirty;
    };

    usePrompt({ when: isDirty(), message: 'Deine Änderungen werden eventuell nicht gespeichert.' });

    useEffect(() => {
        if (!isDirty() && !notSaved && errors.length === 0) {
            window.removeEventListener('beforeunload', handleBeforeunloadEvent);
            // quick hack
            window.location.reload();
        }
    }, [notSaved, errors]);

    return (
        isLookUpLoading || isCustomerLoading ?
            <Row style={{ padding: 50 }} align="middle" justify="center">
                <Spin size="large" />
            </Row> :
            <Layout>
                <Topbar></Topbar>
                <Layout>
                    <Affix>
                        <Sidebar />
                    </Affix>
                    <Layout>
                        <Content style={{
                            padding: 20,
                            boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
                            margin: 20,
                        }}>
                            <SaveWarning open={saveCustomerWarningVisible} message={saveCustomerWarningMessage} onCancel={handleSaveCustomerCancel} onConfirm={handleSaveCustomerConfirm} ></SaveWarning>
                            <Modal open={alertOpen} closable footer={errors.length ? [<Button key="back" onClick={() => setAlertOpen(false)}>
                                schließen
                            </Button>,
                            <Button key="submit" type="primary" onClick={() => { setErrors([]); setAlertOpen(false); handleCustomerSave(); }}>
                                nochmal versuchen
                            </Button>] : null} title={'Die Daten werden gespeichert...'}>
                                <Row justify='center'>
                                    <Spin spinning size='large'></Spin>
                                </Row>
                                <Row>
                                    {
                                        messages.map(message => <p><CheckCircleTwoTone twoToneColor="#52c41a" />{message}</p>)
                                    }
                                </Row>
                                <Row>
                                    {
                                        errors.map(error => <p><CloseCircleTwoTone twoToneColor="#eb2f96" />{error}</p>)
                                    }
                                </Row>
                            </Modal>
                            <ConfirmDelete
                                message={confirmDeleteModal.message}
                                onCancel={() => { setConfirmDeleteModal({ message: '', show: false, indexRemove: -1, onDelete: () => { } }) }}
                                onOk={() => confirmDeleteModal.onDelete(confirmDeleteModal.indexRemove)}
                                visible={confirmDeleteModal.show} />
                            <EditAssociatedPersons
                                key={associatedPersonModalCounter}
                                visible={isAssociatedPersonModalVisible}
                                onCancel={() => setIsAssociatedPersonModalVisible(false) /* TODO clear entries in modal */}
                                onSubmit={() => { setNotSaved(true); setIsAssociatedPersonModalVisible(false); }}
                                mode={editAssociatedPersonMode}
                                associatedPerson={associatedPerson}
                                setAssociatedPerson={setAssociatedPerson}
                                proposedAssociatedPersons={associatedPersons}
                                setProposedAssociatedPersons={setAssociatedPersons}
                                bindungen={bindungen}
                                onBindungenChange={setBindungen}
                                deletedBindungen={deletedBindungen}
                                onDeletedBindungenChange={setDeletedBindungen}
                                currentPerson={currentPerson}
                                lookup={lookup}
                                setLookup={setLookup}
                            />

                            <EditAdresse
                                address={address}
                                mode={editAddressMode}
                                onAddressChange={setAddress}
                                onCancel={handleAddressCancel}
                                onSubmit={() => handleAddressSubmit(address)}
                                visible={isAddressModalVisible}
                                lookupAdressartList={lookup.adressartList}
                            />
                            <EditBankverbindung
                                kunde={currentPerson}
                                bankverbindung={bankverbindung}
                                onBankverbindungChange={setBankverbindung}
                                mode={editBankverbindungMode}
                                onCancel={handleBankverbindungCancel}
                                onSubmit={() => handleBankverbindungSubmit(bankverbindung)}
                                visible={isBankverbindungModalVisible}
                            />
                            <EditKontakt
                                kontakt={contact}
                                onKontaktChange={setContact}
                                visible={contactModalVisible}
                                lookup={lookup}
                                mode={editContactMode}
                                onCancel={() => setContactModalVisible(false)}
                                onSubmit={() => handleContactSubmit(contact)}
                                landCode={getRegionCode(addressData.at(0))}
                            />

                            {/*

                            ================================== The person panel starts here ==================================

                                */}
                            <Space style={{ float: 'right' }}><SyncState kunde={currentPerson} /></Space>
                            <Divider style={{ marginTop: 'auto' }}>{fmt.kundeMitZusatz(currentPerson)}</Divider>
                            <Steps type='navigation' current={currentStep} onChange={curr => {
                                if (currentStep === 0 && currentPerson.name === '') {
                                    message.warning(noNameWarningMessage);
                                } else {
                                    if (!(currentPerson.smartDifferent || currentPerson.smartInnovation)) {
                                        message.warning('Wessen Kunde ist die Person?');
                                    } else {
                                        setCurrentStep(_prev => {
                                            sessionStorage.setItem(TAB_KEY, curr.toString());
                                            return curr;
                                        });
                                    }
                                }
                            }}
                                items={
                                    steps.map(step => (
                                        {
                                            key: step.title,
                                            title: step.title,
                                        }
                                    ))
                                }
                            >
                            </Steps>
                            <Content>{steps[currentStep].content}</Content>
                            <Row style={{ marginTop: 20 }} justify='center'>
                                <Col span={3}>
                                    {currentStep > 0 && (
                                        <Button size='middle' onClick={() => prev()}>
                                            zurück
                                        </Button>
                                    )}
                                    {currentStep < steps.length - 1 && (
                                        <Button size='middle' style={{ marginLeft: 5 }} onClick={() => next()}>
                                            weiter
                                        </Button>
                                    )}
                                </Col>
                                <Col span={21}>
                                    <Button size='middle' style={{ float: 'right', marginRight: 5 }} type="primary" onClick={() => handleCustomerSave()}>
                                        speichern
                                    </Button>
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Layout>
            </Layout >
    )
}

const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

const isValidEMail = (value: string): boolean => emailPattern.test(value)

export const __tests__ = {
    isValidEMail,
};
